import React from "react";
import "./Popup.css";
import Web3 from "web3";

import RegisterPage from "views/examples/LoginPage";
import BkgdImg from "assets/img/ill/register_bg.png";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Button,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Fade,
} from "reactstrap";

import FortmaticIcon from "../../../assets/img/icons/fortmatic.png";

import Fortmatic from "fortmatic";

import Box from "@material-ui/core/Box";
import { BigNumber, providers, utils } from "ethers";
import { PrivateKey } from "@textile/hub";

import { decodeCleartext, prepareCleartext } from "dag-jose-utils";

import { create as createIpfs } from "ipfs";
import { convert as toLegacyIpld } from "blockcodec-to-ipld-format";
import * as Block from "multiformats/block";
import { sha256 } from "multiformats/hashes/sha2";
import * as dagCbor from "@ipld/dag-cbor"; // for decoding the signed payload
import * as dagJose from "dag-jose";

//Metamask
import MetaMaskIcon from "../../../assets/img/icons/metamask.png";

import Zoom from "@material-ui/core/Zoom";

import CancelIcon from "@material-ui/icons/Cancel";

import IconButton from "@material-ui/core/IconButton";

function Popup(props) {
  const [accounts, setAccounts] = React.useState([]);
  const [signature1, setSignature1] = React.useState([]);

  async function signupFortmatic() {
    // Async functions that triggers login modal, if user not already logged in
    let fm = new Fortmatic("pk_live_A69DE2764230C019");
    let web3 = new Web3(fm.getProvider());

    const accounts1 = await web3.eth.getAccounts();

    const _signature = await web3.eth.personal.sign(
      web3.utils.utf8ToHex(
        "Hi! Welcome to SecureMeeting. \n We are requesting you to sign this message to secure your account and your information. \n \n Wallet Address \n{" +
          accounts[0] +
          "}"
      ),
      accounts[0]
    );

    setSignature1(_signature);

    setAccounts(accounts1[0]);

    console.log(accounts1[0]);

    const hash = utils.keccak256(_signature);

    if (hash === null) {
      throw new Error(
        "No account is provided. Please provide an account to this application."
      );
    }

    const array = hash
      // @ts-ignore
      .replace("0x", "")
      // @ts-ignore
      .match(/.{2}/g)
      .map((hexNoPrefix) => BigNumber.from("0x" + hexNoPrefix).toNumber());

    if (array.length !== 32) {
      throw new Error(
        "Hash of signature is not the correct size! Something went wrong!"
      );
    }

    const _identity = PrivateKey.fromRawEd25519Seed(Uint8Array.from(array));
    console.log(_identity);

    return _identity;
  }

  //Metamask Signature.

  async function signupMetaMask() {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.requestAccounts();
      const signature = await web3.eth.personal.sign(
        web3.utils.utf8ToHex(
          "Hi! Welcome to SecureMeeting. \n We are requesting you to sign this message to secure your account and your information. \n \n Wallet Address \n{" +
            accounts[0] +
            "}"
        ),
        accounts[0]
      );
      setSignature1(signature);
      setAccounts(accounts[0]);

      const hash = utils.keccak256(signature);

      if (hash === null) {
        throw new Error(
          "No account is provided. Please provide an account to this application."
        );
      }

      const array = hash
        // @ts-ignore
        .replace("0x", "")
        // @ts-ignore
        .match(/.{2}/g)
        .map((hexNoPrefix) => BigNumber.from("0x" + hexNoPrefix).toNumber());

      if (array.length !== 32) {
        throw new Error(
          "Hash of signature is not the correct size! Something went wrong!"
        );
      }

      const _identity = PrivateKey.fromRawEd25519Seed(Uint8Array.from(array));
      console.log(_identity);

      return _identity;
    } else {
      window.alert(
        "Non-Ethereum browser detected. Yo should consider trying metamask!"
      );
    }
  }

  if (props.pageType == "createRoom") {
    return props.trigger ? (
      <div className="popup">
        <Zoom in={props.trigger}>
          <div className="popup-inner">
            <IconButton
              aria-label="cancel"
              className="close-btn"
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon />
            </IconButton>

            <br />

            <div className="form-container sign-in-container">
              <Box p={1}>
                <Form action="#" role="form">
                  <h6>Create Room With Account</h6>
                  <br />
                  <div className="social-container">
                    <Button
                      color="white"
                      size="sm"
                      type="button"
                      onClick={async () => {
                        const _signIdentity = await signupFortmatic();

                        window.location.href = "https://meet.securemeeting.org";
                      }}
                      style={{ width: "60px", height: "60px" }}
                    >
                      <span className="btn-inner--icon">
                        <img
                          src={FortmaticIcon}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </span>
                    </Button>
                    <Button
                      onClick={async () => {
                        const signIdentity = await signupMetaMask();

                        window.location.href = "https://meet.securemeeting.org";
                      }}
                      color="white"
                      size="sm"
                      type="button"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <span className="btn-inner--icon">
                        <img
                          src={MetaMaskIcon}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </span>
                    </Button>
                    <br />
                    <br />
                    <br />

                    <Button
                      onClick={() =>
                        (window.location.href =
                          "https://meet.securemeeting.org")
                      }
                      color="secondary"
                    >
                      Without Wallet
                    </Button>
                  </div>
                </Form>
              </Box>
            </div>
          </div>
        </Zoom>
        {props.children}
      </div>
    ) : (
      ""
    );
  } else if (props.pageType == "updateInfo") {
    return props.trigger ? (
      <div className="popup">
        <Zoom in={props.trigger}>
          <div className="popup-inner">
            <IconButton
              aria-label="cancel"
              className="close-btn"
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon />
            </IconButton>

            <br />

            <div className="form-container sign-in-container">
              <Box p={1}>
                <Form action="#" role="form">
                  <h6>Update your account information</h6>
                  <br />
                  <div className="social-container">
                    <Button
                      color="white"
                      size="sm"
                      type="button"
                      onClick={async () => {
                        const _signIdentity = await signupFortmatic();
                        console.log("_signIdentity:", _signIdentity);
                        props.setTrigger(false);
                        props.Change(_signIdentity);
                      }}
                      style={{ width: "60px", height: "60px" }}
                    >
                      <span className="btn-inner--icon">
                        <img
                          src={FortmaticIcon}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </span>
                    </Button>
                    <Button
                      onClick={async () => {
                        const signIdentity = await signupMetaMask();
                        props.setIdentityPop(signIdentity);
                        props.setTrigger(false);
                        props.Change(signIdentity);
                      }}
                      color="white"
                      size="sm"
                      type="button"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <span className="btn-inner--icon">
                        <img
                          src={MetaMaskIcon}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </span>
                    </Button>
                    <br />
                    <br />
                    <br />
                  </div>
                </Form>
              </Box>
            </div>
          </div>
        </Zoom>
        {props.children}
      </div>
    ) : (
      ""
    );
  }
}

export default Popup;
