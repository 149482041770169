import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Fade,
} from "reactstrap";

import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";

// Core Components
import Landing from "assets/img/ill/landing.svg";
import Map from "assets/img/world.png";
import Bowser from "bowser";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
}));

function LandingHeader() {
  const [inputFocus, setInputFocus] = React.useState("");
  const [device, setDevice] = React.useState("");
  const classes = useStyles();

  function goToCreateRoom() {
    window.location.href = "https://meet.securemeeting.org/";
  }

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);

  return (
    <>
      {device == "desktop" ? (
        <Container className="text-left">
          <Row className="align-items-center">
            <Col className="mx-auto" lg="7">
              <h2 className="display-1 text-size-5rem font-weight-600">
                65+ Countries
              </h2>
              <h2 className="display-1 text-size-3rem font-weight-400">
                Thousands of users.
              </h2>
              <p className="h5">
                SecureMeeting embarks on a mission to unite humanity by helping
                thousands of people connect around the world. We will be the
                only place on the Internet where you can have conversations
                without giving up your right to data-privacy and remain
                <b> Free Forever</b>. Your contribution fuels this cause.
              </p>
            </Col>
            <Col lg="5" className="">
              <img src={Map} width="150%" />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="text-center">
          <Row className="align-items-center">
            <Col className="mx-auto" lg="7">
              <h2 className="display-1 text-size-5rem font-weight-600">
                65+ Countries
              </h2>
              <h2 className="display-1 text-size-3rem font-weight-400">
                Thousands of users.
              </h2>
              <p
                className="text-left h5"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
              >
                SecureMeeting embarks on a mission to unite humanity by helping
                thousands of people connect around the world. We will be the
                only place on the Internet where you can have conversations
                without giving up your right to data-privacy and remain
                <b> Free Forever</b>. Your contribution fuels this cause.
              </p>
            </Col>
            <Col lg="5" className="">
              <img src={Map} width="100%" />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default LandingHeader;
