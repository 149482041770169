import React from "react";
import Bowser from "bowser";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";
import ThreeReasons from "components/security/ThreeReasons.js";
import HowItWorks from "components/security/HowItWorks.js";
import Mission from "components/security/Mission";
import Why from "components/security/Why";
import SecurityAccordion from "components/accordions/SecurityAccordion";
import LandingFooter from "components/footers/LandingFooter.js";
import SecurityHeader from "components/headers/SecurityHeader.js";
import MobileFooter from "components/footers/MobileFooter";

function Security() {
  const [device, setDevice] = React.useState("");

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    document.body.classList.add("Security");
    window.scrollTo(0, 0);
    setDevice(getDevice());
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("Security");
    };
  });
  return (
    <>
      <MainNavbar type="primary" />
      <div className="wrapper">
        <ThreeReasons />
        <Why />
        <SecurityAccordion />
        {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
      </div>
    </>
  );
}

export default Security;
