import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Spinner,
} from "reactstrap";

import { getMediumBlogs } from "api";

import Bryan from "assets/img/faces/bryan.jpeg";
import Mukund from "assets/img/faces/mukund.jpeg";
import Marcos from "assets/img/faces/marcos.jpeg";
import Justin from "assets/img/faces/justin.jpeg";

import MainNavbar from "components/navbars/MainNavbar.js";
import Blog5 from "components/blogs/Blogs5";
import Blog6 from "components/blogs/Blogs6";

import Bowser from "bowser";

import MobileFooter from "components/footers/MobileFooter";
import LandingFooter from "components/footers/LandingFooter.js";

function Blog() {
  const [loading, setLoading] = useState(true);
  const [lastestStory, setLatestStory] = useState(null);
  const [stories, setStories] = useState(null);
  const [selectedStories, setSelectedStories] = useState(null);
  const [search, setSearch] = useState("");
  const [device, setDevice] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  function getPicture(name) {
    if (name.indexOf("Mukund") != -1) {
      return Mukund;
    } else if (name.indexOf("Bryan") != -1) {
      return Bryan;
    } else if (name.indexOf("Marcos") != -1) {
      return Marcos;
    } else if (name.indexOf("Justin") != -1) {
      return Justin;
    }
  }
  async function getBlogs() {
    return await getMediumBlogs();
  }

  async function init() {
    let stories = await getBlogs();
    let { feed, items, status } = stories;
    if (status == "ok") {
      setStories(items);
      setLatestStory(items[0]);
      const allStories = [...items];
      allStories.shift();
      setSelectedStories(allStories);
    }
    setLoading(false);
  }

  function searchStories() {
    if (search == "") {
      const allStories = [...stories];
      setLatestStory(allStories[0]);
      allStories.shift();
      setSelectedStories(allStories);
    } else {
      //search

      let results = [];
      for (let i = 0; i < stories.length; i++) {
        if (stories[i].title.indexOf(search) != -1) {
          results.push(stories[i]);
        }
      }

      if (results.length > 0) {
        setLatestStory(results[0]);
        results.shift();
        setSelectedStories(results);
      } else {
        setLatestStory(null);
        setSelectedStories(null);
      }
    }
  }

  useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);

  useEffect(() => {
    if (stories) {
      searchStories();
    }
  }, [search]);

  return (
    <div style={{ backgroundColor: "#13154e" }}>
      <MainNavbar type="transparent" />
      <div
        className="wrapper"
        style={{
          maxWidth: "100vw",
          paddingTop: "15vh",
        }}
      >
        <Container style={{ marginBottom: "30px" }}>
          <Row>
            <Col md="8">
              <h1 className="text-white">SecureMeeting Blog</h1>
            </Col>
            <Col md="4">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className="fa fa-search"
                      aria-hidden="true"
                      style={{
                        color: "rgb(183, 183, 183)",
                        fontSize: 18,
                      }}
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  style={{
                    backgroundColor: "white",
                    color: "Black",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        {lastestStory ? (
          <>
            <Blog6
              title={lastestStory.title}
              thumbnail={lastestStory.thumbnail}
              description={lastestStory.description}
              author={lastestStory.author}
              categories={lastestStory.categories}
              authorPicture={getPicture(lastestStory.author)}
            />
          </>
        ) : null}
        {selectedStories ? (
          <div style={{ marginTop: "35px" }}>
            <Blog5 stories={selectedStories} />
          </div>
        ) : null}

        {loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="text-white text-center">
              <Spinner />
            </h3>
          </div>
        ) : null}

        {!lastestStory && !selectedStories && !loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="text-white text-center">
              We did not find any article, try another search.
            </h3>
          </div>
        ) : null}
      </div>
      {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
    </div>
  );
}

export default Blog;
