import React from "react";

import { Row, Col, Container, Button } from "reactstrap";

//material-ui
import {
  Timeline,
  TimelineOppositeContent,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#FF6262",
    },
  },
});

function AboutTimeline() {
  return (
    <ThemeProvider theme={theme}>
      <div className="section">
        <Container>
          <Row className="pb-3">
            <h1 className="text-primary text-center w-100 mb-0 display-3">
              Our Evolution
            </h1>
            <h3 className="w-100 text-center lead mt-1 p-3">
              Take a look at how we got here.
            </h3>
          </Row>
          <Row className="pt-3">
            <Timeline align="alternate">
              <TimelineItem className="">
                <TimelineOppositeContent>
                  <h4 className="text-secondary">2017-2018</h4>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <h5 className="text-primary">
                    Justin Trugman, Charles Bethin, Mukund Iyengar, and Sid
                    Ahuja get together to work on reducing latency of live
                    streaming on the Internet.{" "}
                  </h5>
                  <h6 className="text-gray">
                    While the primary focus of their work was IPTV, they start
                    conducting experiments on low-latency streaming for the
                    common man.
                  </h6>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <h4 className="text-secondary">2018-2019</h4>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <h5 className="text-primary">
                    Justin Trugman and Mukund Iyengar, as a part of
                    iSTEM@Stevens program, begin to build an easy-to-use
                    video-conferencing platform that needs no downloads,
                    installs, or accounts.
                  </h5>
                  <h6 className="text-gray">
                    They established an initiative called “Rooms For Humanity”,
                    whose mission was to give everyone in cyberspace a free +
                    secure place to meet. Site hosted a few hundred
                    conversations. Justin interned at Google in Mountainview, CA
                    in that summer of 2019. The domain roomsforhumanity.org
                    still exists, and redirects to this site!
                  </h6>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <h4 className="text-secondary">Summer 2019</h4>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <h5 className="text-primary">
                    Xavier Rosario and a team of senior design students work
                    with Mukund Iyengar to extend the foundations laid down by
                    Justin.
                  </h5>
                  <h6 className="text-gray">
                    ‘Rooms For Humanity” evolved to support group-video chat
                    that extends to 10+ people, and a more stable session
                    management protocol. A base version of screen-sharing
                    (Chrome plugin) were released. We rebranded ‘Rooms For
                    Humanity’ as SecureMeeting.
                  </h6>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <h4 className="text-secondary">March 2020</h4>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <h5 className="text-primary">
                    Covid-19 strikes and ravages the world. Bryan Kyritz, Andrew
                    D'Angelo, and Mukund Iyengar significantly redesign the
                    product.
                  </h5>
                  <h6 className="text-gray pb-3">
                    Current version supports 35+ group video-chat,
                    high-definition screen-sharing, public/private chat, and
                    numerous fixes and improvements to overall stability and
                    reliability. Joined by Marlee Kopetsky and Justin Trugman in
                    the summer of 2020, this team of five co-create
                    SecureMeeting.
                    <br /> <br />
                    SecureMeeting embarks on a mission to unite humanity by
                    helping thousands of people in over 45 countries spanning
                    America, Asia, Europe, and Africa come together in a
                    browser.
                  </h6>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <h4 className="text-secondary">Today</h4>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <h5 className="text-primary">501c3 Status</h5>
                  <h6 className="text-gray pb-3">
                    SecureMeeting was born from the idea that in this virtual
                    world, everyone deserves the right to be able to communicate
                    freely and securely. This mission transcends profit which is
                    why we founded as a 501c3 nonprofit organization. Our
                    Federal Tax ID Number is 85–4020988
                  </h6>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default AboutTimeline;
