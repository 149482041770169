
import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";

//local imports
import { NextButton, PrevButton } from "./CarouselButtons";

export default function Carousel({ slides }) {
  const [viewportRef, embla] = useEmblaCarousel();
  const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevButtonEnabled(embla.canScrollPrev());
    setNextButtonEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <div className="carousel-mobile-layout d-flex align-items-center">
      <PrevButton onClick={scrollPrev} enabled={prevButtonEnabled} />
      <div className="carousel-viewport-mobile-layout" ref={viewportRef}>
        <div className="carousel-container-mobile-layout">
          {slides.map((slide) => (
            <div className="slide-mobile-layout" key={slides.indexOf(slide)}>
              <div className="slide-interior-mobile-layout d-flex justify-content-center">
                {slide}
              </div>
            </div>
          ))}
        </div>
      </div>
      <NextButton onClick={scrollNext} enabled={nextButtonEnabled} />
    </div>
  );
}