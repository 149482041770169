import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import Bryan from "assets/img/faces/bryan.jpeg";
import Mukund from "assets/img/faces/mukund.jpeg";
import Marcos from "assets/img/faces/marcos.jpeg";
import Justin from "assets/img/faces/justin.jpeg";
function Blogs5({ stories }) {
  function getPicture(name) {
    if (name.indexOf("Mukund") != -1) {
      return Mukund;
    } else if (name.indexOf("Bryan") != -1) {
      return Bryan;
    } else if (name.indexOf("Marcos") != -1) {
      return Marcos;
    } else if (name.indexOf("Justin") != -1) {
      return Justin;
    }
  }

  function goToStory(title) {
    var re = new RegExp("-", "g");
    let strNoDash = title.replace(re, "_");
    //replace spaces
    var ye = new RegExp(" ", "g");
    let str = strNoDash.replace(ye, "-");

    return "/article/" + str;
  }

  function parseSubtitle(str) {
    let openP = str.indexOf("<p>");
    let closeP = str.indexOf("</p>");

    return str.substring(openP + 3, closeP);
  }

  return (
    <>
      <section className="blogs-5">
        <Container>
          <Row>
            <Col className="mx-auto" md="12">
              <Row>
                {stories.map((story, i) => {
                  let { title, description, author, pubDate, thumbnail } =
                    story;
                  let subtitle = parseSubtitle(description);
                  let cleanedPupDate = pubDate.substring(
                    0,
                    pubDate.indexOf(" ")
                  );

                  let cleanText = subtitle.replace(/<\/?[^>]+(>|$)/g, "");

                  let cleanedSubtitle = cleanText;
                  if (cleanedSubtitle.length >= 120) {
                    cleanedSubtitle = cleanedSubtitle.substring(0, 120) + "...";
                  }

                  let urlToArticle = goToStory(title);
                  return (
                    <Col lg="4" md="6" key={i}>
                      <Card className="card-blog card-plain">
                        <div className="card-image shadow">
                          <a href={urlToArticle}>
                            <img
                              alt="..."
                              className="img rounded"
                              src={thumbnail}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <CardTitle tag="h4">
                            <a href={urlToArticle} style={{ color: "white" }}>
                              {title}
                            </a>
                          </CardTitle>
                          <p className="card-description text-white">
                            {cleanedSubtitle}
                          </p>
                          <CardFooter>
                            <div className="author">
                              <img
                                alt="..."
                                className="avatar img-raised"
                                src={getPicture(author)}
                              ></img>
                              <span className="text-white">{author}</span>
                            </div>
                            <div className="stats stats-right opacity-8 text-white">
                              {cleanedPupDate}
                            </div>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Blogs5;
