import React from "react";

import { Container, Row, Col } from "reactstrap";

import BkgdSvg from "assets/img/ill/p31.svg";
import Button from "reactstrap/lib/Button";
import { Link } from "react-router-dom";

export default function Mission() {
  return (
    <div
      className="section features-3 my-5"
      style={{
        backgroundImage: "url(" + BkgdSvg + ")",
      }}
    >
      <Container>
        <Row className="text-center justify-content-center">
          <Col lg="8" sm="12">
            <h1 className="display-1 text-white">Stand up for privacy</h1>
            <p className="lead text-white">
              If every user donated just $3 every month, we would have already
              built the safest meeting place for all of mankind. <br /> <br />
              Make online surveillance history.
            </p>
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="w-100 pt-5">
            <Link
              className="btn btn-secondary justify-content-center"
              to="/donate"
            >
              Donate Now
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
