import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

import Bowser from "bowser";
// Core Components
import BkgdSvg from "assets/img/ill/index_bg.svg";
import AboutConvo from "assets/img/AboutConvo.svg";

function AboutHeader() {
  const [device, setDevice] = React.useState("");

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);

  return (
    <>
      <div className="section section-hero section-shaped">
        <div className="page-header">
          <img alt="..." className="bg-image" src={BkgdSvg}></img>
          <Container className="shape-container d-flex align-items-center pt-9 pb-2">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center" lg="8">
                  <h1 className="display-2">Allow us to introduce ourself</h1>
                  <p className="lead text-primary px-4">
                    We are a team of ordinary people, who want to do
                    extraordinary things.
                  </p>
                  <div className="btn-wrapper mt-0">
                    <img
                      alt="..."
                      src={AboutConvo}
                      style={{ width: "50vh" }}
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutHeader;
