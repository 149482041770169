import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

export const SMAPIURL = process.env.REACT_APP_SM_API || "http://localhost:5000";
export const EMAILAPIURL =
  process.env.NODE_ENV === "production"
    ? "https://smemail.azurewebsites.net"
    : "http://localhost:8001";
export const AUTHAPIURL =
  process.env.NODE_ENV === "production"
    ? "https://smauth.azurewebsites.net"
    : "http://localhost:8000";

export const SERVERAPIURL =
  process.env.NODE_ENV === "production"
    ? "https://smservermanagement.azurewebsites.net/"
    : "http://localhost:8003";

console.log("-----------------------------");
console.log("SM starting up...");
console.log("EMAILAPIURL:", EMAILAPIURL);
console.log("AUTHAPIURL:", AUTHAPIURL);
console.log("-----------------------------");
/*
===========================================================
                _   _     
     /\        | | | |    
    /  \  _   _| |_| |__  
   / /\ \| | | | __| '_ \ 
  / ____ \ |_| | |_| | | |
 /_/    \_\__,_|\__|_| |_|
                          
===========================================================
 */

export function requestLogin(publicKey, stayLoggedIn) {
  const urlEndpoint = AUTHAPIURL + "/auth/login";
  const request = {
    publicKey: publicKey,
    stayLoggedIn: stayLoggedIn,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  });
}

export function requestSignup(publicKey, CID) {
  const urlEndpoint = AUTHAPIURL + "/auth/signup";
  const request = {
    publicKey: publicKey,
    CID: CID,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function requestUpdateAccount(publicKey, CID) {
  const urlEndpoint = AUTHAPIURL + "/auth/update";
  const request = {
    publicKey: publicKey,
    CID: CID,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function checkLoggedIn() {
  const urlEndpoint = AUTHAPIURL + "/auth/isSignedIn";
  const request = {};

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function reestablishSession() {
  const urlEndpoint = AUTHAPIURL + "/auth/reestablishSession";

  return new Promise((resolve, reject) => {
    axios
      .get(urlEndpoint, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function signout() {
  const urlEndpoint = AUTHAPIURL + "/auth/signout";

  return new Promise((resolve, reject) => {
    axios
      .get(urlEndpoint, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function sendForgotPasswordEmail(email) {
  const urlEndpoint = SMAPIURL + "/auth/forgotPassword";
  const request = {
    email: email,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function requestResetPassword(code, password) {
  const urlEndpoint = SMAPIURL + "/auth/resetPassword";
  const request = {
    code: code,
    password: password,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

export function getToken() {
  return localStorage.getItem("auth_token");
}

//----------------------------------------------
//Email
//----------------------------------------------

export function requestSubscribeEmailList(email) {
  const urlEndpoint = EMAILAPIURL + "/email/subscribeToMailingList";
  const request = {
    email: email,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(urlEndpoint, request)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response ? error.response.data : {});
      });
  });
}

//----------------------------------------------
//server
//----------------------------------------------
export function getAllServers() {
  const urlEndpoint = SERVERAPIURL + "/server/getAll";

  console.log("calling", urlEndpoint);
  return new Promise((resolve, reject) => {
    axios
      .get(urlEndpoint)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
        console.error("getAllRooms", error);
      });
  });
}

export function getInfoIp(ip) {
  const urlEndpoint = "http://ip-api.com/json/" + ip;

  console.log("calling", urlEndpoint);
  return new Promise((resolve, reject) => {
    axios
      .get(urlEndpoint)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
        console.error("getAllRooms", error);
      });
  });
}

export function getMediumBlogs() {
  const url =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/securemeeting";

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  });
}
