import React, { useEffect } from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

import DonorBoxDonateForm from "./DonorBoxDonateForm";
import DonorBoxWall from "./DonorBoxDonateWall";
// Core Components

function DonorBoxDonate() {
  return (
    <>
      <div className="section features-1 mt-4">
        <Container>
          <Row>
            <Col className="mx-auto" md="5">
              <DonorBoxDonateForm />
            </Col>
            <Col className="mx-auto" md="5">
              <p className="font-weight-300">
                The team at SecureMeeting is committed to the mission of
                developing the safest virtual meeting place on the internet. We
                will be the only place on the Internet where you can have
                conversations without giving up your right to data-privacy. Your
                contribution fuels this cause.
              </p>
              <p className="font-weight-300">
                Your donation helps pay for the development, servers, and
                bandwidth of an app used by thousands around the world for
                private and instantaneous communication.
              </p>
              <p className="font-weight-300">
                If you provide an email, you will receive an email confirmation
                for your tax records. SecureMeeting is an independent nonprofit
                and tax-exempt under section 501c3 of the Internal Revenue Code.
                Our Federal Tax ID Number is 85-4020988.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto text-center" md="9">
              <div className="section features-1 mt-4">
                <h2 className=" text-secondary mt-1 font-weight-normal">
                  Thank You To Our Generous Donors
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto text-center" md="9">
              <DonorBoxWall />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DonorBoxDonate;
