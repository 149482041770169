import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// Core Components

function AboutFeature() {
  return (
    <>
      <div className="section features-1 mt-4">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="9">
              <h2 className="display-2">Our Story</h2>
              <h4 className="text-secondary mt-3">
                Uniting humanity on a browser. One meeting at a time.{" "}
              </h4>
              <p className="lead font-weight-300">
                SecureMeeting is a movement with the simple goal to provide the
                world with the safest virtual meeting place. We are the only
                place on the Internet where you can have conversations without
                giving up your right to data-privacy. Our core group consists of
                students, scientists, entrepreneurs, developers and designers
                who have embarked on a journey to redefine conversations of the
                Internet.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutFeature;
