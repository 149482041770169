import React from 'react';

//local imports
import  TeamCardFront from "./TeamCardFront";


export default function TeamCard(props) {
    return (
        <div>
           {props.children}
        </div>
    )
}
