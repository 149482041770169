import React from "react";
import Bowser from "bowser";

import MainNavbar from "components/navbars/MainNavbar.js";
import AboutFeature from "components/features/AboutFeature.js";
import SponserFeature from "components/features/SponserFeature.js";
import AboutHeader from "components/headers/AboutHeader";
import AboutTimeline from "components/features/AboutTimeline";
import LandingFooter from "components/footers/LandingFooter";
import MobileFooter from "components/footers/MobileFooter";
import AboutTeamPractice from "components/teams/AboutTeamPractice";

function AboutUs() {
  const [device, setDevice] = React.useState("");

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    setDevice(getDevice());
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <MainNavbar type="primary" />
      <div className="wrapper">
        <AboutHeader />
        <AboutTeamPractice />
        <SponserFeature />
        <AboutTimeline />
        {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
      </div>
    </>
  );
}

export default AboutUs;
