import React, { useEffect } from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// Core Components

function DonorBoxDonateWall() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://donorbox.org/widget.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        frameborder="0"
        name="donorbox"
        scrolling="no"
        seamless="seamless"
        src="https://donorbox.org/embed/securemeeting?only_donor_wall=true"
        style={{
          width: "100%",
          height: "500px",
          maxWidth: "500px",
          minWidth: "310px",
          maxHeight: "none !important",
        }}
      ></iframe>
    </div>
  );
}

export default DonorBoxDonateWall;
