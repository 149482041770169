import React from "react";
import Bowser from "bowser";
// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";
import PricingComponent from "components/tables/Table2.js";
import PricingHeader from "components/headers/PricingHeader.js";

import LandingFooter from "components/footers/LandingFooter.js";
import MobileFooter from "components/footers/MobileFooter";
function Pricing() {
  const [device, setDevice] = React.useState("");

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    document.body.classList.add("Pricing");
    window.scrollTo(0, 0);
    setDevice(getDevice());
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("Pricing");
    };
  });
  return (
    <>
      <MainNavbar type="primary" />
      <div className="wrapper">
        <PricingHeader />
        <PricingComponent />
        {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
      </div>
    </>
  );
}

export default Pricing;
