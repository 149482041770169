import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";

function Blogs6({
  title,
  thumbnail,
  description,
  author,
  categories,
  authorPicture,
}) {
  const [subtitle, setSubtitle] = useState("");
  function parseSubtitle(str) {
    let openP = str.indexOf("<p>");
    let closeP = str.indexOf("</p>");

    return str.substring(openP + 3, closeP);
  }

  function init() {
    setSubtitle(parseSubtitle(description));
  }

  function goToStory() {
    //replaces dash
    var re = new RegExp("-", "g");
    let strNoDash = title.replace(re, "_");
    //replace spaces
    var ye = new RegExp(" ", "g");
    let str = strNoDash.replace(ye, "-");

    window.location.href = "/article/" + str;
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <section className="blogs-6">
        <Container>
          <Row>
            <Col className="mx-auto" lg="12">
              <Card
                className="card-blog card-background card-grande"
                data-animation="zooming"
                style={{ cursor: "pointer" }}
                onClick={goToStory}
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage: "url(" + thumbnail + ")",
                  }}
                ></div>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {" "}
                </a>
                <CardBody className="mb-4">
                  <CardTitle tag="h2">{title}</CardTitle>
                  <p className="card-description text-white">{subtitle}</p>
                  <div className="author">
                    <img alt="..." className="avatar" src={authorPicture}></img>
                    <span className="text-white">by {author}</span>
                  </div>
                  <br></br>

                  <div>
                    {categories.map((categorie, i) => {
                      let styles = {
                        marginLeft: "10px",
                      };

                      if (i == 0) {
                        styles = {
                          marginLeft: "0px",
                        };
                      }
                      return (
                        <Badge
                          key={i}
                          className="badge-floating border-white"
                          color="secondary"
                          size="md"
                          style={styles}
                        >
                          {categorie}
                        </Badge>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Blogs6;
