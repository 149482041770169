import React from "react";
import Bowser from "bowser";
// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";
import SupportContact from "components/contact-us/Support-Contact.js";
import SupportAccordion from "components/accordions/SupportAccordion.js";
import LandingFooter from "components/footers/LandingFooter.js";
import MobileFooter from "components/footers/MobileFooter";

function Support() {
  const [device, setDevice] = React.useState("");

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };
  React.useEffect(() => {
    document.body.classList.add("Support");
    window.scrollTo(0, 0);
    setDevice(getDevice());
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("Support");
    };
  });
  return (
    <>
      <MainNavbar type="primary" />

      <div className="wrapper">
        <SupportContact />
        <SupportAccordion />
        {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
      </div>
    </>
  );
}

export default Support;
