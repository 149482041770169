import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Fade,
} from "reactstrap";

import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";

// Core Components
import Landing from "assets/img/ill/landing.svg";

import Popup from "./components/Popup";

import Bowser from "bowser";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
}));

function LandingHeader() {
  const [inputFocus, setInputFocus] = React.useState("");
  const [roomName, setRoomName] = React.useState("");
  const [device, setDevice] = React.useState("");

  const [buttonPopup, setButtonPopup] = React.useState(false);
  const [purpose, setPurpose] = React.useState("Create Room With Account");

  const [checked, setChecked] = React.useState(false);

  const [identity, setIdentity] = React.useState([]);

  const classes = useStyles();

  function goToCreateRoom() {
    window.location.href = "https://meet.securemeeting.org/";
  }

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);

  return (
    <>
      <header className="header-4 skew-separator">
        <div className="header-wrapper">
          <Navbar className="navbar-transparent" expand="lg">
            <Container>
              <div className="navbar-translate">
                <NavbarBrand
                  className="text-white"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  SecureMeeting
                </NavbarBrand>
                <button
                  aria-controls="navbarSupportedContent"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#example-header-4"
                  data-toggle="collapse"
                  id="example-header-4"
                  type="button"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <UncontrolledCollapse
                id="example-header-4"
                navbar
                toggler="#example-header-4"
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        SecureMeeting
                      </a>
                    </Col>
                    <Col className="collapse-close text-right" xs="6">
                      <button
                        aria-controls="navigation-index"
                        aria-expanded={false}
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        data-target="#example-header-4"
                        data-toggle="collapse"
                        id="example-header-4"
                        type="button"
                      >
                        <span></span>
                        <span></span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="mx-auto" navbar>
                  <NavItem>
                    <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                      Products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                      Contact Us
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav className="nav navbar-right" navbar>
                  <NavItem>
                    <NavLink href="https://twitter.com/CreativeTim">
                      <i className="fab fa-twitter"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://www.facebook.com/CreativeTim">
                      <i className="fab fa-facebook-square"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://www.instagram.com/CreativeTimOfficial">
                      <i className="fab fa-instagram"></i>
                    </NavLink>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          <div className="page-header header-video">
            <div className="overlay" style={{ opacity: "1" }}></div>

            {device == "desktop" ? (
              <Container className="text-left">
                <Row className="align-items-center">
                  <Col className="mx-auto" lg="6">
                    <h2 className="display-1  text-white text-size-5rem font-weight-600">
                      Converse Freely.
                    </h2>
                    <h1 className=" h3 text-light">
                      Defend yourself against tracking & surveillance.
                      Circumvent Censorship.
                    </h1>
                    <Row className="align-items-center justify-content-left py-5 pl-2">
                      <Col lg="4" sm="3" xs="5" className="p-0">
                        <Button
                          className="btn-icon mb-sm-0"
                          color="secondary"
                          onClick={goToCreateRoom}
                        >
                          <span className="btn-inner--text">Create Room</span>
                        </Button>
                      </Col>
                      <Col lg="4" sm="3" xs="7" className="p-lg-0 pr-sm-1">
                        <FormGroup
                          className={inputFocus}
                          style={{ marginBottom: "0" }}
                        >
                          <InputGroup></InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" className="">
                    <object type="image/svg+xml" data={Landing} width="150%">
                      svg
                    </object>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container className="text-center">
                <Row className="align-items-center">
                  <Col className="mx-auto" lg="6">
                    <h2 className="display-1  text-white text-size-5rem font-weight-600">
                      Converse Freely.
                    </h2>
                    <h1 className=" h3 text-light">
                      Defend yourself against tracking & surveillance.
                      Circumvent Censorship.
                    </h1>
                    <Row
                      className="align-items-center justify-content-center pl-2"
                      style={{ paddingTop: "3rem" }}
                    >
                      <Col className="mx-auto" lg="6">
                        <Button
                          className="btn-icon mb-sm-0"
                          color="secondary"
                          onClick={goToCreateRoom}
                        >
                          <span className="btn-inner--text">Create Room</span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" className="">
                    <object type="image/svg+xml" data={Landing} width="150%">
                      svg-animation
                    </object>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default LandingHeader;
