import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

// Core Components
import IStem from "assets/img/istem.svg";
import Launchpad from "assets/img/launchpad.svg";
import Stevens from "assets/img/Stevens.svg";

//{props.color === "white" ? "section features-7 bg-lighter" : "section features-7"
function SponserFeature(props) {
  return (
    <>
      <div
        className="section features-7 "
        style={{
          backgroundColor: props.color == "white" ? "white" : "#e9ecef",
          paddingTop: props.color === "white" ? "0rem" : "4rem",
        }}
      >
        <Container>
          <Row>
            <Col className="text-center mx-auto" md="8">
              <h3 className="display-3">Sponsers</h3>
              <p className="lead font-weight-300">
                We are proudly associated with the following initiatives at
                Stevens Institute of Technology in Hoboken, New Jersey, for
                their continued support and endorsement.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col lg="12">
              <Row className="row-grid">
                <Col className="d-flex justify-content-center" lg="4">
                  <img src={IStem} alt="..." />
                </Col>
                <Col className="d-flex justify-content-center" lg="4">
                  <img src={Launchpad} alt="..." />
                </Col>
                <Col className="d-flex justify-content-center" lg="4">
                  <img src={Stevens} alt="..." />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SponserFeature;
