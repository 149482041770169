import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import AboutUs from "views/examples/AboutUs.js";
import Security from "views/examples/Security.js";
import Pricing from "views/examples/Pricing.js";
import MaintenancePage from "views/examples/MaintenancePage";
import AccountSettings from "views/examples/AccountSettings";
import Error404 from "views/examples/Error404.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import Support from "views/examples/Support.js";
import Donate from "views/examples/Donate";
import Mission from "views/examples/Mission";
import Blog from "views/examples/Blog";
import BlogPost from "views/examples/BlogPost";

import IpfsContext from "./IpfsContext";
import * as dagJose from "dag-jose";
import { create as createIpfs } from "ipfs";
import { convert as toLegacyIpld } from "blockcodec-to-ipld-format";
const dagJoseIpldFormat = toLegacyIpld(dagJose);

function App() {
  const [ipfs, setIpfs] = useState([]);

  async function setupIpfs() {
    // Instantiate an IPFS node, that knows how to deal with DAG-JOSE blocks
    if (ipfs.length < 1) {
      let temp = await createIpfs({
        ipld: { formats: [dagJoseIpldFormat] },
      });

      setIpfs(temp);
    }
  }

  useEffect(() => {
    setupIpfs();
  }, []);

  return (
    <IpfsContext.Provider value={ipfs}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => <LandingPage {...props} />}
          />
          <Route
            path="/landing"
            exact
            render={(props) => <LandingPage {...props} />}
          />
          <Route path="/blog" exact render={(props) => <Blog {...props} />} />
          <Route path="/article" render={(props) => <BlogPost {...props} />} />
          <Route
            path="/about"
            exact
            render={(props) => <AboutUs {...props} />}
          />
          <Route
            path="/mission"
            exact
            render={(props) => <Mission {...props} />}
          />
          <Route
            path="/security"
            exact
            render={(props) => <Security {...props} />}
          />
          <Route
            path="/support"
            exact
            render={(props) => <Support {...props} />}
          />
          <Route
            path="/error"
            exact
            render={(props) => <Error404 {...props} />}
          />
          <Route
            path="/login"
            exact
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            path="/sign-up"
            exact
            render={(props) => <RegisterPage {...props} />}
          />
          <Route path="/donate" render={(props) => <Donate {...props} />} />
          <Route
            path="/account"
            render={(props) => <AccountSettings {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </IpfsContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
