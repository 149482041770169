import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
//local imports
import SMlogo from "../../assets/img/SM_logo_white.svg";
import SMlogoblue from "../../assets/img/SM_logo_blue.svg";

import { useHistory } from "react-router-dom";
import { logout } from "../../accounts";
import makeBlockie from "ethereum-blockies-base64";

function DemoNavbar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const history = useHistory();

  function goToAccount() {
    history.push("/account");
  }

  function goToDashboard() {
    window.location.href = "https://meet.securemeeting.org/dashboard";
  }

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"));

    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }

    // initialise
    headroom.init();
  }, []);
  let navbarType = "";
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      break;
    case "white":
      navbarType = "bg-white";
      break;
    default:
      navbarType = "bg-default navbar-dark";
      break;
  }
  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"
      >
        <Container>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <img alt="..." src={SMlogo} style={{ height: "30px" }}></img>
          </NavbarBrand>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleCollapse(!collapseOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
            className="justify-content-center"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="10">
                  <Link to="/">
                    <img alt="..." src={SMlogoblue}></img>
                    <span className="h5 px-3">SecureMeeting</span>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="2">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto"
              navbar
            >
              {/*
              <UncontrolledDropdown nav>
                <DropdownToggle
                  data-toggle="dropdown"
                  href=""
                  onClick={(e) => e.preventDefault()}
                  role="button"
                  tag={NavLink}
                >
                  <i class="fas fa-info-circle d-lg-none"></i>
                  <span className="nav-link-inner--text">About</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl">
                  <div className="dropdown-menu-inner">
                    <Media
                      className="d-flex align-items-center"
                      to="/about"
                      tag={Link}
                    >
                      <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                        <i class="fa fa-info-circle"></i>
                      </div>
                      <Media body className="ml-3">
                        <h6 className="heading text-primary mb-md-1">
                          About Us
                        </h6>
                        <p className="description d-none d-md-inline-block mb-0">
                          Learn more about SecureMeeting and our mission.
                        </p>
                      </Media>
                    </Media>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              */}
              <li className="nav-item">
                <a href="/about" className="nav-link">
                  <i className="fas fa-info-circle d-lg-none d-lg-none pr-1"></i>
                  <span className="nav-link-inner--text">About</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/security" className="nav-link">
                  <i className="ni ni-lock-circle-open d-lg-none pr-1"></i>
                  <span className="nav-link-inner--text">Security</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/mission" className="nav-link">
                  <i className="ni ni-map-big d-lg-none pr-1"></i>
                  <span className="nav-link-inner--text">Mission</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/blog" className="nav-link">
                  <i className="ni ni-collection d-lg-none pr-1"></i>
                  <span className="nav-link-inner--text">Blog</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/support" className="nav-link">
                  <i className="ni ni-chat-round d-lg-none pr-1"></i>
                  <span className="nav-link-inner--text">Support</span>
                </a>
              </li>
            </Nav>
            <div className="navbar-collapse-footer ml-lg-auto">
              <Row>
                <Col>
                  <Button
                    tag={Link}
                    to="/donate"
                    color="secondary"
                    style={{ color: "white" }}
                  >
                    Donate
                  </Button>
                </Col>
                {user === null && (
                  <Col>
                    <Button
                      outline
                      tag={Link}
                      to="/login"
                      color="sky"
                      style={{ color: "#6ABCFF" }}
                    >
                      Login
                    </Button>
                  </Col>
                )}
                {user && (
                  <div
                    className="d-flex justify-content-end mb-3"
                    style={{
                      marginTop: "5px",
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    <a
                      className="avatar avatar-sm rounded-circle"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={
                          user.profilePic && user.profilePic != ""
                            ? user.profilePic
                            : makeBlockie(user.wallet)
                        }
                      />
                    </a>
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        className="text-white"
                        data-toggle="dropdown"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        id="navbar-primary_dropdown_1"
                        nav
                        role="button"
                      >
                        {user.displayName}
                      </DropdownToggle>
                      <DropdownMenu
                        aria-labelledby="navbar-primary_dropdown_1"
                        right
                      >
                        <DropdownItem onClick={(e) => goToAccount()}>
                          <i className=" ni ni-single-02"></i>
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem divider></DropdownItem>
                        <DropdownItem onClick={(e) => goToDashboard()}>
                          <i className=" ni ni-single-02"></i>
                          <span>Dashboard</span>
                        </DropdownItem>
                        <DropdownItem divider></DropdownItem>
                        <DropdownItem onClick={(e) => logout()}>
                          <i className=" ni ni-user-run"></i>
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                )}
              </Row>
            </div>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

DemoNavbar.defaultProps = {
  type: "dark",
};

DemoNavbar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default DemoNavbar;
