import React, { useEffect, useContext } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Spinner,
} from "reactstrap";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

import { useHistory } from "react-router-dom";

import clsx from "clsx";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";

import BkgdImg from "assets/img/ill/register_bg.png";
import { Link } from "react-router-dom";

import Fade from "@material-ui/core/Fade";

import { requestLogin, requestSignup } from "../../api";

//Formatic
import Fortmatic from "fortmatic";
import FortmaticIcon from "../../assets/img/icons/fortmatic.png";

//Web3
import Web3 from "web3";

//Metamask
import MetaMaskIcon from "../../assets/img/icons/metamask.png";

//User-Data
import {
  PrivateKey,
  Client,
  Buckets,
  UserAuth,
  ThreadID,
  Where,
} from "@textile/hub";
import CID from "cids";
import { BigNumber, providers, utils } from "ethers";
import { hashSync } from "bcryptjs";
//import * as Block from "multiformats/block";
//import { sha256 } from "multiformats/hashes/sha2";
//import * as dagCbor from "@ipld/dag-cbor"; // for decoding the signed payload
import * as dagJose from "dag-jose";
import {
  xc20pDirEncrypter,
  xc20pDirDecrypter,
  decryptJWE,
  createJWE,
  JWE,
} from "did-jwt";

import { decodeCleartext, prepareCleartext } from "dag-jose-utils";

import { create as createIpfs } from "ipfs";
import { convert as toLegacyIpld } from "blockcodec-to-ipld-format";
import { randomBytes } from "@stablelib/random";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import { string } from "prop-types";
import { ContactSupportOutlined } from "@material-ui/icons";

import IpfsContext from "../../IpfsContext";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function RegisterPage() {
  const [activeContainer, setActiveContainer] =
    React.useState("right-panel-active");

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [signupNameFocus, setSignupNameFocus] = React.useState("");
  const [signupEmailFocus, setSignupEmailFocus] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [accounts, setAccounts] = React.useState([]);
  const [signature1, setSignature1] = React.useState([]);
  const steps = getSteps();

  const history = useHistory();

  const ipfs = useContext(IpfsContext);

  //IPFS
  //const [ipfs, setIpfs] = React.useState([]);
  const [cid, setCid] = React.useState([]);
  const [identity, setIdentity] = React.useState([]);

  const dagJoseIpldFormat = toLegacyIpld(dagJose);

  React.useEffect(() => {
    /*
    async function setup() {
      // Instantiate an IPFS node, that knows how to deal with DAG-JOSE blocks
      if (ipfs.length < 1) {
        console.log("Starting IPFS ...");
        setIpfs(await createIpfs({ ipld: { formats: [dagJoseIpldFormat] } }));
      }
    }
    setup();
    */
    console.log(ipfs);
  }, []);

  const storeEncrypted = async (payload, key) => {
    if (key == undefined) {
      throw alert("Sign in with your wallet fisrt");
    }

    const dirEncrypter = xc20pDirEncrypter(key);
    // prepares a cleartext object to be encrypted in a JWE
    const cleartext = await prepareCleartext(payload);
    // encrypt into JWE container layout using secret key
    const jwe = await createJWE(cleartext, [dirEncrypter]);
    // let IPFS store the bytes using the DAG-JOSE codec and return a CID
    const cid = await ipfs.dag.put(jwe, {
      format: "dag-cbor",
      hashAlg: "sha2-256",
    });
    console.log(`Encrypted block CID: \u001b[32m${cid}\u001b[39m`);
    return cid;
  };

  const loadEncrypted = async (cid, key) => {
    const dirDecrypter = xc20pDirDecrypter(key);
    const retrieved = await ipfs.dag.get(cid);
    const decryptedData = await decryptJWE(retrieved.value, dirDecrypter);
    return decodeCleartext(decryptedData);
  };

  async function storeSigned() {
    const key = randomBytes(32);

    const secretz = {
      email: email,
      displayName: name,
      wallet: accounts,
    };

    console.log(
      "Encrypting and storing secret:\u001b[1m",
      secretz,
      "\u001b[22m"
    );
    const cid = await storeEncrypted(secretz, identity.pubKey);
    const signupResponse = await requestSignup(accounts, cid.string);
    console.log(signupResponse);
    console.log("base encoded:");

    if (signupResponse.isSuccess) {
      const cid1 = new CID(signupResponse.payload.CID);
      console.log(cid1);
      const decoded = await loadEncrypted(cid, identity.pubKey);
      console.log(
        "Loaded and decrypted block content:\u001b[1m",
        decoded,
        "\u001b[22m"
      );
      console.log(decoded);

      onSuccessfullLogin(decoded, "");
    } else {
      setError(signupResponse.errorName);
    }
  }

  //Formatic'
  async function signupFortmatic() {
    // Async functions that triggers login modal, if user not already logged in
    let fm = new Fortmatic("pk_live_A69DE2764230C019");
    let web3 = new Web3(fm.getProvider());

    const accounts1 = await web3.eth.getAccounts();

    const _signature = await web3.eth.personal.sign(
      "Hello World ",
      accounts1[0]
    );

    console.log(identity);

    setSignature1(_signature);

    setAccounts(accounts1[0]);

    console.log(accounts1[0]);

    const hash = utils.keccak256(_signature);

    if (hash === null) {
      throw new Error(
        "No account is provided. Please provide an account to this application."
      );
    }

    const array = hash
      // @ts-ignore
      .replace("0x", "")
      // @ts-ignore
      .match(/.{2}/g)
      .map((hexNoPrefix) => BigNumber.from("0x" + hexNoPrefix).toNumber());

    if (array.length !== 32) {
      throw new Error(
        "Hash of signature is not the correct size! Something went wrong!"
      );
    }

    const _identity = PrivateKey.fromRawEd25519Seed(Uint8Array.from(array));
    console.log(_identity);

    setActiveStep(1);

    return _identity;
  }

  async function loginFortmatic() {
    // Async functions that triggers login modal, if user not already logged in
    let fm = new Fortmatic("pk_live_A69DE2764230C019");
    let web3 = new Web3(fm.getProvider());

    const accounts1 = await web3.eth.getAccounts();

    const _signature = await web3.eth.personal.sign(
      "Hello World ",
      accounts1[0]
    );

    console.log(identity);

    setSignature1(_signature);

    setAccounts(accounts1[0]);

    console.log(accounts1[0]);

    const hash = utils.keccak256(_signature);

    if (hash === null) {
      throw new Error(
        "No account is provided. Please provide an account to this application."
      );
    }

    const array = hash
      // @ts-ignore
      .replace("0x", "")
      // @ts-ignore
      .match(/.{2}/g)
      .map((hexNoPrefix) => BigNumber.from("0x" + hexNoPrefix).toNumber());

    if (array.length !== 32) {
      throw new Error(
        "Hash of signature is not the correct size! Something went wrong!"
      );
    }

    const _identity = PrivateKey.fromRawEd25519Seed(Uint8Array.from(array));
    console.log(_identity);

    console.log("publickey:", identity.pubKey);

    loginWithIdentity(identity.pubKey);

    return _identity;
  }

  //Metamask

  async function LoadBloackchain() {
    const web3 = new Web3(window.ethereum);

    if (typeof web3 !== "undefined") {
      const netId = await web3.eth.net.getId();
      const accounts = await web3.eth.requestAccounts();
    } else {
      window.alert("nono");
    }

    setAccounts(accounts);
  }

  //Metamask Signature.

  async function signupMetaMask() {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);

      const accounts = await web3.eth.requestAccounts();

      const signature = await web3.eth.personal.sign(
        web3.utils.utf8ToHex("Hello World "),
        accounts[0]
      );

      setSignature1(signature);

      setAccounts(accounts[0]);

      console.log(accounts[0]);

      const compare = parseInt(accounts[0]);

      const recover = await web3.eth.personal.ecRecover(
        web3.utils.utf8ToHex("Hello World "),
        signature
      );

      const recover1 = parseInt(recover);
      console.log(recover);

      const hash = utils.keccak256(signature);

      if (hash === null) {
        throw new Error(
          "No account is provided. Please provide an account to this application."
        );
      }

      const array = hash
        // @ts-ignore
        .replace("0x", "")
        // @ts-ignore
        .match(/.{2}/g)
        .map((hexNoPrefix) => BigNumber.from("0x" + hexNoPrefix).toNumber());

      if (array.length !== 32) {
        throw new Error(
          "Hash of signature is not the correct size! Something went wrong!"
        );
      }

      const _identity = PrivateKey.fromRawEd25519Seed(Uint8Array.from(array));
      console.log(_identity);

      setActiveStep(1);

      return _identity;
    } else {
      window.alert(
        "Non-Ethereum browser detected. Yo should consider trying metamask!"
      );
    }
  }

  async function loginMetaMask() {
    if (window.ethereum) {
      const key = randomBytes(32);
      const web3 = new Web3(window.ethereum);

      const accounts = await web3.eth.requestAccounts();

      const signature = await web3.eth.personal.sign(
        web3.utils.utf8ToHex("Hello World "),
        accounts[0]
      );

      setSignature1(signature);

      setAccounts(accounts[0]);

      console.log(accounts[0]);

      const compare = parseInt(accounts[0]);

      const recover = await web3.eth.personal.ecRecover(
        web3.utils.utf8ToHex("Hello World "),
        signature
      );

      const recover1 = parseInt(recover);
      console.log(recover);

      const hash = utils.keccak256(signature);

      if (hash === null) {
        throw new Error(
          "No account is provided. Please provide an account to this application."
        );
      }

      const array = hash
        // @ts-ignore
        .replace("0x", "")
        // @ts-ignore
        .match(/.{2}/g)
        .map((hexNoPrefix) => BigNumber.from("0x" + hexNoPrefix).toNumber());

      if (array.length !== 32) {
        throw new Error(
          "Hash of signature is not the correct size! Something went wrong!"
        );
      }

      const _identity = PrivateKey.fromRawEd25519Seed(Uint8Array.from(array));
      console.log(_identity);

      loginWithIdentity(_identity.pubKey);

      return _identity;
    } else {
      window.alert(
        "Non-Ethereum browser detected. Yo should consider trying metamask!"
      );
    }
  }

  async function loginWithIdentity(identity) {
    const loginResponse = await requestLogin(accounts, true);

    console.log("response:", loginResponse);
    if (loginResponse.isSuccess) {
      const stringCID = loginResponse.payload.CID;
      console.log("stringCID:", stringCID);
      const cid = new CID(stringCID);
      console.log(cid);
      console.log("pubKey:", identity);

      const decoded = await loadEncrypted(cid, identity);
      console.log(
        "Loaded and decrypted block content:\u001b[1m",
        decoded,
        "\u001b[22m"
      );
      console.log(decoded);
      onSuccessfullLogin(decoded, loginResponse.token);
    } else {
      setError(loginResponse.errorName);
    }
  }

  //-----------------------------------------Helper functions-----------------------------------------

  function onSuccessfullLogin(user, token) {
    console.log("user:", user);
    console.log("token:", token);
    const stringUser = JSON.stringify(user);
    console.log("saving ", stringUser);
    localStorage.setItem("user", stringUser);
    history.push("/");
  }

  //-----------------------------------------Main functions-----------------------------------------

  function getSteps() {
    return ["Select a Wallet", "Setup Account"];
  }

  React.useEffect(() => {
    document.body.classList.add("register-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  }, []);

  function WalletButtonsSignup(p) {
    return (
      <div className="social-container">
        <Button
          color="white"
          size="sm"
          type="button"
          onClick={async () => {
            const _1identity = await signupFortmatic();
            setIdentity(_1identity);
          }}
          style={{ width: "60px", height: "60px" }}
        >
          <span className="btn-inner--icon">
            <img
              src={FortmaticIcon}
              style={{ width: "40px", height: "40px" }}
            />
          </span>
        </Button>
        <Button
          onClick={async () => {
            const _12identity = await signupMetaMask();
            setIdentity(_12identity);
          }}
          color="white"
          size="sm"
          type="button"
          style={{ width: "60px", height: "60px" }}
        >
          <span className="btn-inner--icon">
            <img src={MetaMaskIcon} style={{ width: "40px", height: "40px" }} />
          </span>
        </Button>
        <br />
        <br />
        <p>What is this?</p>
      </div>
    );
  }

  function AccountInformation(p) {
    return (
      <>
        <span className="text-default mb-4"></span>
        <FormGroup className={"mb-3 " + signupNameFocus}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-circle-08"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Display Name"
              type="text"
              onChange={(event) => setName(event.target.value)}
            ></Input>
          </InputGroup>
        </FormGroup>
        <FormGroup className={"mb-3 " + signupEmailFocus}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="email"
              onChange={(event) => setEmail(event.target.value)}
            ></Input>
          </InputGroup>
        </FormGroup>
        {error ? (
          <span className="text-danger mb-2" styles={{ color: "red" }}>
            {error}
          </span>
        ) : null}
        {loading ? (
          <Button color="primary" disabled>
            <span className="btn-inner--icon">
              <Spinner size="sm" color="secondary" />
            </span>
            <span className="btn-inner--text"> Sign Up</span>
          </Button>
        ) : (
          <Button to="/Pricing" color="primary" onClick={storeSigned}>
            Sign Up
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <div className="page-header bg-default">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(" + BkgdImg + ")",
            }}
          ></div>
          <Container className={activeContainer}>
            <div className="form-container sign-up-container">
              <Form>
                <h2>Create Account</h2>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {activeStep === 0 && WalletButtonsSignup()}
                {activeStep === 1 && AccountInformation()}
              </Form>
            </div>
            <div className="form-container sign-in-container">
              <Form action="#" role="form">
                <h3>Sign In</h3>
                <div className="social-container">
                  <Button
                    color="white"
                    size="sm"
                    type="button"
                    onClick={async () => {
                      const _1identity = await signupFortmatic();
                      setIdentity(_1identity);
                    }}
                    style={{ width: "60px", height: "60px" }}
                  >
                    <span className="btn-inner--icon">
                      <img
                        src={FortmaticIcon}
                        style={{ width: "40px", height: "40px" }}
                      />
                    </span>
                  </Button>
                  <Button
                    onClick={async () => {
                      const identityLogin = await loginMetaMask();
                      setIdentity(identityLogin);
                    }}
                    color="white"
                    size="sm"
                    type="button"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <span className="btn-inner--icon">
                      <img
                        src={MetaMaskIcon}
                        style={{ width: "40px", height: "40px" }}
                      />
                    </span>
                  </Button>
                </div>
                <p>What is this?</p>
              </Form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h2 className="text-white">Already have an account?</h2>
                  <p>Sign into SecureMeeting with your wallet.</p>
                  <Button
                    className="btn-neutral"
                    color="default"
                    id="signIn"
                    size="sm"
                    onClick={() => {
                      setError(null);
                      setActiveContainer("");
                    }}
                  >
                    Sign In
                  </Button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h2 className="text-white">Don't have an account?</h2>
                  <p>Connect your wallet and start journey with us</p>
                  <Button
                    className="btn-neutral"
                    color="default"
                    id="signUp"
                    size="sm"
                    onClick={() => {
                      setError(null);
                      setActiveContainer("right-panel-active");
                    }}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
