import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  NavLink,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import SMlogo from "../../assets/img/SM_logo_white.svg";

import { requestSubscribeEmailList } from "../../api";
// Core Components

function LandingFooter() {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  function onHandleEmailChaned(e) {
    setEmail(e.target.value);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function subscribeToMailingList() {
    setError(null);
    setSuccess(false);
    setLoading(true);

    if (validateEmail(email)) {
      const response = await requestSubscribeEmailList(email);
      const { isSuccess, errorName, payload } = response;

      if (isSuccess) {
        setSuccess(true);
      } else {
        setError(errorName);
      }
    } else {
      setError("Please enter a valid email.");
    }
    setLoading(false);
  }

  return (
    <>
      <footer className="footer footer-big bg-gradient-default">
        <Container>
          <div className="content">
            <Row className="mb-5">
              <div className="column mx-auto d-flex align-items-center">
                <img
                  alt="..."
                  className="logo mt-0 mr-3 logo-sm"
                  src={SMlogo}
                ></img>
                <span> </span>
                <h4 className="text-white m-0">SecureMeeting</h4>
              </div>
            </Row>
            <Row>
              <Col md="2" xs="4">
                <div className="column">
                  <h4 className="mb-4">About Us</h4>
                  <ul className="links-vertical">
                    <Link to="/about">
                      <li>
                        <a>About</a>
                      </li>
                    </Link>
                    <Link to="/security">
                      <li>
                        <a>Security</a>
                      </li>
                    </Link>
                    <Link to="/mission">
                      <li>
                        <a>Mission</a>
                      </li>
                    </Link>
                    <li>
                      <a
                        href="https://medium.com/secure-meeting"
                        target="_blank"
                      >
                        Blog
                      </a>
                    </li>
                    <Link to="/support">
                      <li>
                        <a>Support</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </Col>

              <Col md="2" xs="4">
                <div className="column">
                  <h4 className="mb-4">Social</h4>
                  <ul className="links-vertical">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/securemeeting/"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://medium.com/secure-meeting"
                        target="_blank"
                      >
                        Medium
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/securemeeting/"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/secure_meeting"
                        target="_blank"
                      >
                        Twitter
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="2" xs="4">
                <div className="column">
                  <h4 className="mb-4">Community</h4>
                  <ul className="links-vertical">
                    <li>
                      <a href="https://discord.gg/cj3kKj3T" target="_blank">
                        Discord
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://securemeeting-support.slack.com/"
                        target="_blank"
                      >
                        Slack
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/+OzNJvSOwQPUzNzM5" target="_blank">
                        Telegram
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="6">
                <div className="column">
                  <h4 className="mb-4">Newsletter</h4>
                  <ul className="links-vertical">
                    <p>
                      Stay tuned with weekly updates and recieve information
                      regarding our latest content!
                    </p>
                  </ul>
                  <Form
                    action=""
                    className="form form-newsletter row align-items-center flex-row px-2 justify-content-center"
                    method=""
                  >
                    <FormGroup className="col-md-8 m-0">
                      <Input
                        placeholder="Your Email"
                        type="email"
                        onChange={onHandleEmailChaned}
                        value={email}
                      ></Input>
                    </FormGroup>

                    <Button
                      color="secondary"
                      name="button"
                      type="button"
                      className="col-md-3"
                      style={{ top: 0, width: "50%" }}
                      onClick={subscribeToMailingList}
                    >
                      Subscribe
                    </Button>
                  </Form>
                  <div>
                    <br />
                    {loading ? (
                      <div className="row justify-content-center align-items-center">
                        <Spinner size="sm" color="secondary" />{" "}
                      </div>
                    ) : null}
                    {error ? (
                      <h6 className="text-danger text-center w-100 ">
                        {error}
                      </h6>
                    ) : null}
                    {success ? (
                      <h6 className="text-success text-center w-100 ">
                        Successfully added to the mailing list!
                      </h6>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <hr className="bg-white opacity-3"></hr>
          <div className="row justify-content-center align-items-center">
            <ul
              className="d-flex justify-content-center align-items-center pl-4 m-0"
              style={{ listStyle: "none" }}
            >
              <li>
                <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                  Terms &amp; conditions
                </NavLink>
              </li>
              <li>
                <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                  Privacy policy
                </NavLink>
              </li>
              <li>
                <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                  Legal notice
                </NavLink>
              </li>
            </ul>
            {/* <div className="text-right d-flex justify-content-center pt-2 pr-4">
              <ul className="social-buttons">
                <li>
                  <Button
                    className="btn-icon mr-2"
                    color="twitter"
                    href="https://twitter.com/CreativeTim"
                    size="sm"
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </Button>{" "}
                </li>
                <li>
                  <Button
                    className="btn-icon mr-2"
                    color="facebook"
                    href="https://www.facebook.com/CreativeTim"
                    size="sm"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn-icon"
                    color="instagram"
                    href="https://www.instagram.com/CreativeTimOfficial"
                    size="sm"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                </li>
              </ul>
            </div> */}
          </div>
        </Container>
      </footer>
    </>
  );
}

export default LandingFooter;
