import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import BlogImageOne from "assets/img/BlogImageOne.jpeg";
import BlogImageTwo from "assets/img/BlogImageTwo.png";

function LandingBlogs() {
  return (
    <>
      <section className="blogs-2 pt-8 pb-4">
        <Container fluid>
          <Row className="mb-md-5">
            <Col className="mx-auto" md="8">
              <h3 className="display-3 text-center">Our recent writings</h3>
              {/* <p className="lead text-center">
                Catch up on our latest blog posts. 
              </p> */}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage: "url(" + BlogImageOne + ")",
                  }}
                ></div>
                <a
                  target="_blank"
                  href="https://medium.com/secure-meeting/take-back-control-of-your-digital-life-in-6-easy-steps-e3da6e4111d4?source=collection_home---------1----------------------------"
                >
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category text-white opacity-8">
                        Security and surveillance tips
                      </h6>
                      <CardTitle tag="h5">Take Back Control</CardTitle>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage: "url(" + BlogImageTwo + ")",
                  }}
                ></div>
                <a
                  href="https://medium.com/secure-meeting/webrtc-architecture-basics-p2p-sfu-mcu-and-hybrid-approaches-e2aea14c80f9"
                  target="_blank"
                >
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category text-white opacity-8">
                        Spectrum
                      </h6>
                      <CardTitle tag="h5">Data Virtualization</CardTitle>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default LandingBlogs;
