import React from "react";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import Bowser from "bowser";
import { Link } from "react-router-dom";

export default function OurStory() {
  const [device, setDevice] = React.useState("");
  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);
  return (
    <div className="section" style={{ paddingTop: "0rem" }}>
      {device === "desktop" ? (
        <Container className="px-5">
          <Row className="justify-content-centerp-3 m-3">
            <Col className="p-3">
              <h1 className="text-center w-100 font-weight-bold pt-3 pb-4 display-3">
                Our Story
              </h1>
              <h5 className="text-center px-6">
                SecureMeeting is a movement with the simple goal to provide the
                world with the safest virtual meeting place. We are the only
                place on the Internet where you can have conversations without
                giving up your right to data-privacy. Our core group consists of
                students, scientists, entrepreneurs, developers and designers
                who have embarked on a journey to redefine conversations of the
                Internet.
              </h5>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row className="justify-content-centerp-3 m-3">
            <Col className="p-3">
              <h1 className="text-center w-100 font-weight-bold pt-3 pb-4 display-3">
                Our Story
              </h1>
              <h5 className="text-left ">
                SecureMeeting is a movement with the simple goal to provide the
                world with the safest virtual meeting place. We are the only
                place on the Internet where you can have conversations without
                giving up your right to data-privacy. Our core group consists of
                students, scientists, entrepreneurs, developers and designers
                who have embarked on a journey to redefine conversations of the
                Internet.
              </h5>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
