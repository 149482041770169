import React from "react";

const IpfsContext = React.createContext();

export default IpfsContext;

export function withRoomContext(Component) {
  return (props) => (
    <IpfsContext.Consumer>
      {(ipfs) => <Component {...props} ipfs={ipfs} />}
    </IpfsContext.Consumer>
  );
}
