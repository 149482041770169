import React from "react";

export default function TestimonialCard({ picture, name, testimonial }) {
  return (
    <div className="card-component testimonial-card">
      <div className="row justify-content-center">
        <img src={picture} className="round testimonial-pic" />
      </div>
      <div className="w-100 mt-4">
        <h3 className="testimonial-card-title text-center semi-bold">{name}</h3>
      </div>
      <div className="w-100 mt-4 px-4">
        <p className="testimonial-text text-center color-gray">{testimonial}</p>
      </div>
    </div>
  );
}