import React from "react";

import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import Pdf from "../../assets/whitepaper.pdf";

import { Container, Row, Col } from "reactstrap";

import BkgdSvg from "assets/img/ill/p31.svg";
import Button from "reactstrap/lib/Button";
import { Link } from "react-router-dom";

export default function Mission() {
  function openWhitePaper() {
    window.open(Pdf, "_blank");
  }
  return (
    <div
      className="section features-3 my-5"
      style={{
        backgroundImage: "url(" + BkgdSvg + ")",
      }}
    >
      <Container>
        <Row className="text-left justify-content-left">
          <Col lg="12" sm="12">
            <h1 className="display-2 text-white">Our approach to security</h1>
            <p className="lead text-fog">
              Our mission is to build the safest meeting place on the Internet.
              <br /> <br />
              Learn more about how we keep you safe.
              <br />
              <br />
              <Button
                className="btn-icon mb-sm-0"
                color="secondary"
                onClick={openWhitePaper}
              >
                <span className="btn-inner--text">Read White Paper</span>
              </Button>
              <br />
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
