import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";

import Image from "assets/img/ill/404.svg";

function MaintenancePage() {
  React.useEffect(() => {
    document.body.classList.add("maintenance-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("maintenance-page");
    };
  });
  return (
    <>
      <div className="wrapper">
        <div className="page-header maintenance-page">
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${Image})`,
            }}
          ></div>
          <Container>
            <Row>
              <Col className="text-center" md="12">
                <h1 className="title">Sorry!</h1>
                <p className="lead">
                  We're down for Maintenance right now and will be back up
                  shortly.
                </p>
                <p className="lead">
                  If you have any questions you can contact us at
                  hello@securemeeting.org
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default MaintenancePage;
