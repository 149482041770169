import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import SkewedContainer from "sc-react";

// Core Components
import WorldMap from "assets/img/WorldMap.svg";
import Landing from "assets/img/ill/landing.svg";
import dotenv from "dotenv";

dotenv.config();

function PracticeLanding() {
  const [inputFocus, setInputFocus] = React.useState("");
  const [roomname, setRoomName] = React.useState("");

  function goToCreatePage() {
    let url = "";
    if (process.env.REACT_APP_NODE_ENV === "development") {
      url = "http://localhost:3001/create";
    } else {
      url = "https://meet.securemeeting.org/create";
    }
    window.location.href = url;
  }

  function goToRoomName() {
    let url = "";
    if (process.env.NODE_ENV === "development") {
      url = "http://localhost:3001/" + roomname;
    } else {
      url = "https://meet.securemeeting.org/" + roomname;
    }
    window.location.href = url;
  }

  function onRoomNameChanged(e) {
    console.log(e.target.value);
    setRoomName(e.target.value);
    console.log(process.env);
  }

  return (
    <>
      <header className="header-4 skew-separator">
        {/* <SkewedContainer className="pt-0" bottom="right" bgColor="#0d1b4e"> */}

        <div className="page-header header-video">
          <div className="overlay" style={{ opacity: "1" }}></div>

          <Container className="text-left pb-0 pt-2">
            <Row className="align-items-center">
              <Col className="mx-auto" lg="6">
                <h2 className="display-1  text-white text-size-5rem font-weight-600">
                  Converse Freely.
                </h2>
                <h1 className=" h3 text-light">
                  Defend yourself against tracking & surveillance. Circumvent
                  Censorship.
                </h1>
                <Row className="align-items-center justify-content-left py-5 pl-2">
                  <Col lg="4" sm="3" xs="5" className="p-0">
                    <Button
                      className="btn-icon mb-sm-0"
                      color="secondary"
                      onClick={goToCreatePage}
                    >
                      <span className="btn-inner--text">Create Room</span>
                    </Button>
                  </Col>
                  <Col lg="4" sm="3" xs="7" className="p-lg-0 pr-sm-1">
                    <FormGroup
                      className={inputFocus}
                      style={{ marginBottom: "0" }}
                    >
                      <InputGroup>
                        <Input
                          placeholder="Enter room code..."
                          type="text"
                          onFocus={() => setInputFocus("focused")}
                          onBlur={() => setInputFocus("")}
                          onChange={onRoomNameChanged}
                          value={roomname}
                        ></Input>
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="py-0 px-0">
                            <Button
                              className="btn-icon btn-2 py-1 px-3 btn-white h-100"
                              color="default"
                              type="button"
                              style={{ boxShadow: "unset" }}
                              onClick={goToRoomName}
                            >
                              <span className="btn-inner--icon">
                                <i class="fas fa-sign-in-alt"></i>
                              </span>
                            </Button>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col lg="6">
                  <object type="image/svg+xml" data={Landing} width="150%" >svg-animation</object>
              </Col>
            </Row>
          </Container>
        </div>

        {/* </SkewedContainer> */}
      </header>
    </>
  );
}

export default PracticeLanding;
