import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Local Imports
import Button from "reactstrap/lib/Button";
import ThreePointsImg from "assets/img/ill/securityfeat.svg";
import SectionBkgd from "assets/img/ill/1.svg";

function ThreeMainPoints() {
  function learnMoreAccounts() {
    window.open(
      "https://medium.com/securemeeting/non-intrusive-web-3-0-accounts-a-securemeeting-perspective-f943b6d12194",
      "_blank"
    );
  }
  function learnMoreEncryption() {
    window.open(
      "https://medium.com/securemeeting/e2e-encryption-arrived-to-securemeeting-1d9aee42c41b",
      "_blank"
    );
  }
  return (
    <>
      <div className="section features-6">
        <Container className="pt-0 mt-0">
          <Row className="align-items-center">
            <Col lg="6">
              <h2 className="display-2 mt-3">
                <br />
                <b>Introducing a new level to security.</b>
              </h2>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i class="fas fa-user-shield text-info"></i>
                </div>
                <div className="description pl-4">
                  <h4 className="title">Decentralized</h4>
                  <p className="font-weight-normal">
                    Your account is stored in decentralized nodes. Accounts are
                    invisible to us. Authenticate yourself using Web3 wallets.
                  </p>
                  <Button
                    className="btn-icon mb-sm-0"
                    color="secondary"
                    onClick={learnMoreAccounts}
                  >
                    <span className="btn-inner--text">Learn More</span>
                  </Button>
                </div>
              </div>
              <div className="info info-horizontal info-hover-danger">
                <div className="icon icon-shape icon-shape-danger rounded-circle text-white">
                  <i className="ni ni-active-40 text-danger"></i>
                </div>
                <div className="description pl-4">
                  <h4 className="title">Compliant</h4>
                  <p className="font-weight-normal">
                    Our security suite transcends the requirements of HIPAA and
                    GDPR. We take these extra measures so you can converse
                    freely.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i class="fas fa-power-off text-info"></i>
                </div>
                <div className="description pl-4">
                  <h4 className="title">True End-to-End Encryption</h4>
                  <p className="font-weight-normal">
                    True end-to-end encryption. We encrypt every packet from
                    your video and audio data and never expose content when in
                    transit.
                  </p>
                  <Button
                    className="btn-icon mb-sm-0"
                    color="secondary"
                    onClick={learnMoreEncryption}
                  >
                    <span className="btn-inner--text">Learn More</span>
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="mx-md-auto" lg="6" xs="10">
              <img
                alt="..."
                className="ml-lg-5"
                src={ThreePointsImg}
                width="120%"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ThreeMainPoints;
