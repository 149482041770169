import React from "react";

// reactstrap components
import {
  Badge,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

// Core Components

function HowItWorks() {
  return (
    <>
      <div className="section features-1">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="8">
              <h3 className="display-3">How It Works</h3>
              <p className="lead">
                {" "}
                All you need is a browser and a camera. <br />
              </p>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card className="card-profile" data-image="img-rounded">
                <div
                  className="card-avatar d-flex justify-content-center align-items-center bg-secondary opacity-8"
                  style={{ height: "5rem", width: "5rem" }}
                >
                  <h1 className="text-center text-white">1</h1>
                </div>
                <CardBody>
                  <CardTitle tag="h4" className="display-3">
                    Create
                  </CardTitle>
                  <p className="card-description px-2 text-center">
                    Create a “room”. A room is where you’ll host your meeting by
                    inviting others to it.
                  </p>
                  {/* <Button
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Follow
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile" data-image="img-rounded">
                <div
                  className="card-avatar d-flex justify-content-center align-items-center bg-primary opacity-10"
                  style={{ height: "5rem", width: "5rem" }}
                >
                  <h1 className="text-center text-white">2</h1>
                </div>
                <CardBody>
                  <CardTitle tag="h4" className="display-3">
                    Invite
                  </CardTitle>
                  <p className="card-description px-2 text-center">
                    Invite others to your room by sharing the room link. One
                    click is all it takes to join.
                  </p>
                  {/* <Button
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Follow
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile" data-image="img-rounded">
                <div
                  className="card-avatar d-flex justify-content-center bg-sky align-items-center opacity-0"
                  style={{ height: "5rem", width: "5rem" }}
                >
                  <h1 className="text-center text-white">3</h1>
                </div>
                <CardBody>
                  <CardTitle tag="h4" className="display-3">
                    Talk
                  </CardTitle>
                  <p className="card-description px-2 text-center">
                    Enjoy secure, traceless video conversations. Close your
                    browser tab to leave anytime.
                  </p>
                  {/* <Button
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Follow
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HowItWorks;
