import React, { useEffect } from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// Core Components

function DonorBoxDonateForm() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://donorbox.org/widget.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        src="https://donorbox.org/embed/securemeeting?default_interval=o"
        name="donorbox"
        allowpaymentrequest=""
        seamless="seamless"
        frameborder="0"
        scrolling="no"
        height="500px"
        width="100%"
        style={{
          height: "500px",
          maxWidth: "500px",
          minWidth: "310px",
          maxHeight: "none !important",
        }}
      ></iframe>
    </div>
  );
}

export default DonorBoxDonateForm;
