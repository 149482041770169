import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// Core Components
import WorldMap from "assets/img/WorldMap.svg";
import Donate from "assets/img/ill/donate.svg";
import Call from "assets/img/ill/videocall.svg";
import Network from "assets/img/ill/connect.svg";

function DonateHeader() {
  return (
    <>
      <header className="header-4 skew-separator">
        <div className="header-wrapper">
          <div className="page-header header-video">
            <div className="overlay" style={{ opacity: "1" }}></div>

            <Container className="text-left">
              <Row className="align-items-center">
                <Col className="mx-auto" lg="6">
                  <h2 className="display-1 text-white text-size-5rem font-weight-600">
                    Help us change the world,
                  </h2>
                  <h2 className="display-3 text-white text-size-3rem font-weight-500">
                    one conversation at a time.
                  </h2>
                </Col>
                <Col lg="6">
                  <img alt="..." className="" src={Network} width="150%"></img>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
    </>
  );
}

export default DonateHeader;
