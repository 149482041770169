import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

import {Link} from 'react-router-dom';

// Core Components
import PricingBkgd from "assets/img/ill/bg_pricing5.svg";

function LandingPricing() {
  const [activeTab, setActiveTab] = React.useState("tab1");
  return (
    <>
      <section
        className="pricing-5"
        id="pricing-6"
        style={{
          backgroundImage: "url(" + PricingBkgd + ")",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Container className="pt-6 ">
          <div className="d-flex justify-content-center flex-column">
            <h3 className="display-2 mt-3 text-center w-100 pt-md-5">
              Pricing Plans{" "}
            </h3>
            <p className="lead mt-0 w-100 text-center font-weight-normal">
              Find the perfect plan for you.
            </p>
          </div>
          <Row>
            <Col className="ml-auto mr-auto" lg="12" md="12">
              <Row className="align-items-center">
                <Col md="4">
                  <Card className="card-pricing bg-white border-0 text-center mb-4">
                    <CardBody className="pt-3">
                      <h2 className="ls-1 py-2 mb-0 text-primary text-center font-weight-bold">
                        Basic
                      </h2>
                      <h5 className="text-secondary pr-1">
                        <i>Free Forever</i>
                      </h5>

                      <div className="d-flex p-3 justify-content-center">
                        <ul className="d-flex justify-content-start list-unstyled my-4 flex-column">
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <b className="text-primary">Unlimited</b>{" "}
                            <span>1:1 Meetings</span>
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <span className="text-gray font-weight-normal">
                              Meetings up to 1 hour
                            </span>{" "}
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <span className="text-gray font-weight-normal">
                              Max 4 people / room
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </CardBody>
                    <CardFooter className="bg-transparent">
                      <Link
                        className="btn btn-light"
                        to="/"
                      >
                        Try Now
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-pricing bg-white border-0 text-center mb-4">
                    <CardBody className="pt-3">
                      <h2 className="ls-1 py-2 mb-0 text-primary text-center font-weight-bold">
                        Solo
                      </h2>
                      <h6 className="text-gray">
                        <i>Perfect for small teams & solo-preneurs</i>
                      </h6>
                      <div className="display-3 text-secondary">$9.99</div>
                      <span className="gray-dark"> per month</span>

                      <div className="d-flex p-3 justify-content-center">
                        <ul className="d-flex justify-content-start list-unstyled my-4 flex-column">
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <b className="text-primary">Enhanced</b>{" "}
                            <span>security</span>
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <b className="text-primary">Unlimited</b>{" "}
                            <span>meetings</span>
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <span className="text-gray font-weight-normal">
                              Phone bridge
                            </span>{" "}
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info text-left"></i>{" "}
                            <span className="text-gray font-weight-normal">
                              Up to 5-hours of recording
                            </span>
                          </li>
                        </ul>
                      </div>
                    </CardBody>
                    <CardFooter className="bg-transparent">
                      <Link
                        className="btn btn-secondary"
                        to="/sign-up"
                      >
                        Sign up
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-pricing bg-white border-0 text-center mb-4">
                    <CardBody className="pt-3">
                      <h2 className="ls-1 py-2 mb-0 text-primary text-center font-weight-bold">
                        Enterprise
                      </h2>
                      <h5 className="text-secondary pr-1">
                        <i>Perfect for large enterprises</i>
                      </h5>

                      <div className="d-flex p-3 justify-content-center">
                        <ul className="d-flex justify-content-start list-unstyled my-4 flex-column">
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            
                            <span>Proprietary Stack</span>
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <b className="text-primary">Custom</b>{" "}
                            <span>CDN</span>
                          </li>
                          <li className="align-self-start py-2">
                            <i class="fas fa-check-circle text-info"></i>{" "}
                            <span className="text-gray ">
                            Tailor made security plans
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </CardBody>
                    <CardFooter className="bg-transparent">
                      <Link
                        className="btn btn-light"
                        to="/Pricing"
                      >
                        Contact Sales
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <p className="lead text-center pb-8 text-gray">*For more information regarding plans and pricing please visit our pricing page here*</p>
        </Container>
      </section>
    </>
  );
}

export default LandingPricing;
