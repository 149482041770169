import React from 'react';

//icons


export const PrevButton = ({ enabled, onClick}) => {
    return (
        <button className="icon-button-mobile-layout prev-button" onClick={onClick} disabled={!enabled}>
            <i class="fas fa-chevron-left"></i>
        </button>
    );
}

export const NextButton = ({ enabled, onClick}) => {
    return (
        <button className="icon-button-mobile-layout next-button" onClick={onClick} disabled={!enabled}>
            <i class="fas fa-chevron-right"></i>
        </button>
    );
}