import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Local Imports
import ThreePointsImg from "assets/img/ThreePointsImage.svg";
import SectionBkgd from "assets/img/ill/1.svg"

function ThreeMainPoints() {
  return (
    <>
      <div className="section features-6" >
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <h2 className="display-2 mt-3">
                No ads. No Trackers. <b>No kidding.</b>
              </h2>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                <i class="fas fa-user-shield text-info" ></i>
                </div>
                <div className="description pl-4">
                  <h4 className="title">Safe</h4>
                  <p className="font-weight-normal">
                    Decentralized ledgers. Encrypted content. Data always in transit. 
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="info info-horizontal info-hover-danger">
                <div className="icon icon-shape icon-shape-danger rounded-circle text-white">
                  <i className="ni ni-active-40 text-danger"></i>
                </div>
                <div className="description pl-4">
                  <h4 className="title">Easy</h4>
                  <p className="font-weight-normal">
                  Nothing to download or install. Accounts not necessary. 
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                <i class="fas fa-power-off text-info"></i>
                </div>
                <div className="description pl-4">
                  <h4 className="title">Live</h4>
                  <p className="font-weight-normal">
                   Web 3.0 compliant. State of the art technology. Open-sourced technology. Built with love.
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>
            <Col className="mx-md-auto" lg="6" xs="10">
              <img
                alt="..."
                className="ml-lg-5"
                src={ThreePointsImg}
                width="100%"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ThreeMainPoints;
