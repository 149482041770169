import React, { useState, useEffect } from "react";

// reactstrap components

import { Spinner } from "reactstrap";
import { getMediumBlogs } from "api";

import Bryan from "assets/img/faces/bryan.jpeg";
import Mukund from "assets/img/faces/mukund.jpeg";
import Marcos from "assets/img/faces/marcos.jpeg";
import Justin from "assets/img/faces/justin.jpeg";

import MainNavbar from "components/navbars/MainNavbar.js";
import Interweave, { Node } from "interweave";

import MobileFooter from "components/footers/MobileFooter";
import LandingFooter from "components/footers/LandingFooter.js";

import Bowser from "bowser";

function Blog() {
  const [device, setDevice] = React.useState("");
  const [story, setStory] = useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  function getPicture(name) {
    if (name.indexOf("Mukund") != -1) {
      return Mukund;
    } else if (name.indexOf("Bryan") != -1) {
      return Bryan;
    } else if (name.indexOf("Marcos") != -1) {
      return Marcos;
    } else if (name.indexOf("Justin") != -1) {
      return Justin;
    }
  }

  function transform(node, children) {
    if (node.tagName === "A") {
      return (
        <a
          href={node.getAttribute("href")}
          style={{ color: "white", textDecoration: "underline" }}
        >
          {children}
        </a>
      );
    } else if (node.tagName === "H1") {
      return <h3 style={{ color: "white" }}>{children}</h3>;
    } else if (node.tagName === "H2") {
      return <h3 style={{ color: "white" }}>{children}</h3>;
    } else if (node.tagName === "H3") {
      return <h3 style={{ color: "white" }}>{children}</h3>;
    } else if (node.tagName === "H4") {
      return <h3 style={{ color: "white" }}>{children}</h3>;
    } else if (node.tagName === "H5") {
      return <h3 style={{ color: "white" }}>{children}</h3>;
    } else if (node.tagName === "H6") {
      return <h3 style={{ color: "white" }}>{children}</h3>;
    } else if (node.tagName === "IMG") {
      if (node.getAttribute("src").indexOf("https://medium.com") != -1) {
        return;
      }
      return (
        <div
          style={{
            maxWidth: "100%",
            display: "block",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={node.getAttribute("src")}
            style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      );
    } else if (node.tagName === "FIGCAPTION") {
      return <></>;
    } else if (node.tagName === "PRE") {
      return (
        <pre style={{ background: "grey", color: "white" }}>{children}</pre>
      );
    }
  }

  function getTitleFromUrl() {
    let title = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1,
      window.location.href.length
    );

    var re = new RegExp("-", "g");
    let str = title.replace(re, " ");

    var ye = new RegExp("_", "g");
    let strNoDash = str.replace(ye, "-");

    return strNoDash;
  }

  async function getBlogs() {
    return await getMediumBlogs();
  }

  function getPupDate(pubDate) {
    return pubDate.substring(0, pubDate.indexOf(" "));
  }

  async function init() {
    let stories = await getBlogs();
    let { feed, items, status } = stories;
    if (status == "ok") {
      let searchString = getTitleFromUrl();
      console.log("searchString:", searchString);
      for (let i = 0; i < items.length; i++) {
        console.log("title", items[i].title);
        if (items[i].title == searchString) {
          setStory(items[i]);
        }
      }
    }
  }

  function getContent(storyObj) {
    if (storyObj == null) {
      return "";
    }

    let str = storyObj.description;

    let withoutDescription = str.substring(0, str.lastIndexOf("<p>"));

    return withoutDescription;
  }

  useEffect(() => {
    init();
    setDevice(getDevice());
  }, []);

  return (
    <div style={{ backgroundColor: "#13154e" }}>
      <MainNavbar type="transparent" />
      {story ? (
        <section>
          <div
            style={{
              paddingLeft: device == "mobile" ? "7.5vw" : "20vw",
              paddingRight: device == "mobile" ? "7.5vw" : "20vw",
              color: "white",
              paddingTop: "15vh",
            }}
          >
            <h1 className="text-white">{story.title}</h1>
            <div
              className="author"
              style={{
                marginBottom: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <img
                  alt="..."
                  className="avatar img-raised"
                  src={getPicture(story.author)}
                  style={{ width: "60px", height: "60px" }}
                ></img>
              </div>
              <div>
                <p
                  className="text-white"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  {story.author}
                </p>
                <p
                  className="text-white"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  {getPupDate(story.pubDate)}
                </p>
              </div>
            </div>

            <Interweave content={getContent(story)} transform={transform} />
          </div>
        </section>
      ) : (
        <div
          style={{
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className="text-white text-center">Story not found</h3>
        </div>
      )}

      {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
    </div>
  );
}

export default Blog;
