import React from "react";

// reactstrap components
import { Button, Card, CardBody, CardTitle, Col, CardHeader } from "reactstrap";

import Row from "reactstrap/lib/Row";

// Core Components

function TeamCardFront(props) {
  return (
    <>
      <Col>
        <Card>
          <CardHeader
            className="border-bottom-0 pb-0"
            style={{
              borderTopWidth: 5,
              borderTopStyle: "solid",
              borderTopColor: "#ff6262",
            }}
          >
            <img
              alt="..."
              className=""
              src={props.pic}
              style={{
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                padding: 15,
                borderRadius: "50%",
                paddingBottom: 0,
              }}
            ></img>
          </CardHeader>

          <CardBody className="pt-0 mt-3">
            <CardTitle className="text-center" tag="h4">
              {props.name}
            </CardTitle>
            <h6
              className="text-gray pb-2"
              style={{
                textAlign: "center",
              }}
            >
              {props.cofounder ? (
                <>
                  Co-Founder <br />
                </>
              ) : null}
              {props.job}
            </h6>
            <div className="text-center">
              <Button
                className="btn-icon-only rounded-circle "
                color="secondary"
                href={props.linkedin}
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-linkedin-in"></i>
                </span>
              </Button>
              <Button
                className="btn-icon-only rounded-circle"
                color="secondary"
                href={`mailto:${props.emailName}@securemeeting.org`}
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-send"></i>
                </span>
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default TeamCardFront;
