import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";
import LandingFooter from "components/footers/LandingFooter.js";
import MissionHeader from "components/headers/MissionHeader";
import ThreeMainPoints from "components/features/ThreeMainPoints.js";
import HowItWorks from "components/features/HowItWorks.js";
import LandingBlogs from "components/blogs/LandingBlogs.js";
import LandingPricing from "components/pricings/LandingPricing.js";
import LandingTestimonials from "components/testimonials/LandingTestimonials";
import LandingTestimonialsMobile from "components/testimonials/LandingTestimonialsMobile";
import LandingMailList from "components/mail-list/LandingMailList";
import OurStory from "components/teams/OurStory";
import Mission from "components/Misson/Mission";
import SponserFeature from "components/features/SponserFeature.js";
import Worldwide from "components/Misson/Worldwide.js";
import PracticeLanding from "components/headers/PracticeLanding";

import Bowser from "bowser";
import MobileFooter from "components/footers/MobileFooter";

function LandingPage() {
  //state
  const [device, setDevice] = React.useState("");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);
  return (
    <>
      <MainNavbar type="transparent" />
      <div className="wrapper">
        <MissionHeader />
        <OurStory />
        <Worldwide />
        <Mission />
        <SponserFeature color={"white"} />
        {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
      </div>
    </>
  );
}

export default LandingPage;
