import React from "react";
import Bowser from "bowser";
// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar.js";
import LandingFooter from "components/footers/LandingFooter.js";
import DonateHeader from "components/headers/DonateHeader";
import DonateFeature from "components/features/DonateFeature";
import DonatePricing from "components/pricings/DonatePricing";
import DonorBoxDonate from "components/features/DonorBoxDonate";
import MobileFooter from "components/footers/MobileFooter";

function Donate() {
  const [device, setDevice] = React.useState("");

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };
  React.useEffect(() => {
    document.body.classList.add("donate");
    window.scrollTo(0, 0);
    setDevice(getDevice());
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("donate");
    };
  });
  return (
    <>
      <MainNavbar type="transparent" />
      <div className="wrapper">
        <DonateHeader />
        <DonorBoxDonate />
        {device === "mobile" ? <MobileFooter /> : <LandingFooter />}
      </div>
    </>
  );
}

export default Donate;
