import React from "react";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Spinner,
} from "reactstrap";

import MailListSvg from "assets/img/MailListSvg.svg";

import { requestSubscribeEmailList } from "../../api";

export default function LandingMailList() {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  function onHandleEmailChaned(e) {
    setEmail(e.target.value);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function subscribeToMailingList() {
    setError(null);
    setSuccess(false);
    setLoading(true);
    if (validateEmail(email)) {
      const response = await requestSubscribeEmailList(email);
      const { isSuccess, errorName, payload } = response;

      if (isSuccess) {
        setSuccess(true);
      } else {
        setError(errorName);
      }
    } else {
      setError("Please enter a valid email.");
    }
    setLoading(false);
  }

  return (
    <div className="section">
      <Container>
        <Row
          className="justify-content-center bg-white p-3 m-2"
          style={{
            border: "2px solid #8898aa",
            boxShadow: "0 0 10px inset #8898aa",
            borderRadius: "15px",
          }}
        >
          <Col>
            <Row className="mb-2">
              <h1 className="text-primary text-center w-100 font-weight-bold pb-2 display-2 pt-1">
                Never miss a thing.
              </h1>
              <h5 className="text-gray text-center w-100 ">
                Sign-up to our newsletter to be updated with all things
                SecureMeeting
              </h5>
            </Row>
            <Row className="p-2">
              <Col md={6}>
                <img
                  src={MailListSvg}
                  alt="..."
                  style={{ width: "100%", height: "100%" }}
                />
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <Form
                  action=""
                  className="form form-newsletter w-100 d-flex align-items-center flex-column"
                  method=""
                >
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Your Email"
                      type="email"
                      className="w-100"
                      onChange={onHandleEmailChaned}
                      value={email}
                    ></Input>
                  </FormGroup>
                  <Button
                    color="secondary"
                    name="button"
                    type="button"
                    className="w-50"
                    onClick={subscribeToMailingList}
                  >
                    Subscribe
                  </Button>
                  <div>
                    <br />
                    {loading ? <Spinner size="sm" color="secondary" /> : null}
                    {error ? (
                      <h6 className="text-danger text-center w-100 ">
                        {error}
                      </h6>
                    ) : null}
                    {success ? (
                      <h6 className="text-success text-center w-100 ">
                        Successfully added to the mailing list!
                      </h6>
                    ) : null}
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
