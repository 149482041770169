import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// Core Components
import HeaderImage from "assets/img/ill/bg_contact2.svg";
import HeaderImageMobile from "assets/img/ill/bg_contact_mobile.svg";

import { ReactComponent as DiscordIcon } from "../../assets/img/icons/discord.svg";
import { ReactComponent as SlackIcon } from "../../assets/img/icons/slack.svg";
import { ReactComponent as TelegramIcon } from "../../assets/img/icons/telegram.svg";

import Bowser from "bowser";

function SupportContact() {
  const [device, setDevice] = React.useState("");

  function goToSlack() {
    window.open("https://securemeeting-support.slack.com/", "_blank");
  }

  function goToDiscord() {
    window.open("  https://discord.gg/GfcgygqBYS", "_blank");
  }

  function goToTelegram() {
    window.open("https://t.me/+OzNJvSOwQPUzNzM5", "_blank");
  }

  const getDevice = () => {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType();
  };

  React.useEffect(() => {
    setDevice(getDevice());
  }, [getDevice]);

  return (
    <>
      <div className="contactus-3">
        <div className="page-header-image pb-8">
          <img
            alt="..."
            className="bg-image"
            src={HeaderImage}
            width="100%"
          ></img>
        </div>
        <Container className="pt-0 pb-9">
          <Row>
            <Col className="text-center " md="12">
              <h1 className="display-1 mt-3">Got a question?</h1>
              <h3 className="lead">
                Join our communities! We'd like to talk more about what you
                need.
              </h3>
              <Button
                className="btn-icon mt-3"
                color="primary"
                type="button"
                onClick={goToSlack}
              >
                <span className="btn-inner--icon">
                  <SlackIcon
                    style={{
                      height: "20px",
                      width: "20px",
                      fill: "white",
                    }}
                  />
                </span>
                <span className="btn-inner--text">Slack</span>
              </Button>
              <Button
                className="btn-icon mt-3"
                color="primary"
                type="button"
                onClick={goToDiscord}
              >
                <span className="btn-inner--icon">
                  <DiscordIcon
                    style={{
                      height: "20px",
                      width: "20px",
                      fill: "white",
                    }}
                  />
                </span>
                <span className="btn-inner--text">Discord</span>
              </Button>
              <Button
                className="btn-icon mt-3"
                color="primary"
                type="button"
                onClick={goToTelegram}
              >
                <span className="btn-inner--icon">
                  <TelegramIcon
                    style={{
                      height: "20px",
                      width: "20px",
                      fill: "white",
                    }}
                  />
                </span>
                <span className="btn-inner--text">Telegram</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SupportContact;
