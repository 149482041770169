import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function SupportAccordion() {
  const [openedCollapse, setOpenedCollapse] = React.useState("collapse-1");
  return (
    <>
      <div className="accordion-1  pt-6">
        <Container>
          <Row className="pb-0 mb-0">
            <Col className="mx-auto text-center" md="8">
              <h2 className="title mb-2 mt-5 font-weight-bold">FAQ</h2>
              <h6 className="">
                We are here to help. </h6>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="12">
              <div className="accordion" id="accordionExample">
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-1"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-1" ? "" : "collapse-1"
                          )
                        }
                        className="w-100 text-primary text-left collapsed"
                        color="link"
                        type="button"
                      >
                        What browser works best for SecureMeeting?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-1"}>
                    <CardBody className="opacity-8">
                    We highly recommend Chrome. Or Firefox. If you are serious about 
                    blocking trackers, we also recommend Brave. We do not guarantee 
                    support for Safari or Edge at this time.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingTwo">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-2"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-2" ? "" : "collapse-2"
                          )
                        }
                        className="w-100 text-primary text-left collapsed"
                        color="link"
                        type="button"
                      >
                        Password protect rooms: Two layers{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-2"}>
                    <CardBody className="opacity-8">
                    When creating a room, you have an option of password protecting it right away. 
                    The room name/link and password must now be shared with the people you want to 
                    invite. When the session is in progress, we allow hosts to further reset the 
                    password to a brand new one. This thwarts the inadvertent advertisement of room 
                    credentials by any one of the participants prior to joining the call.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingThree">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-3"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-3" ? "" : "collapse-3"
                          )
                        }
                        className="w-100 text-primary text-left collapsed"
                        color="link"
                        type="button"
                      >
                        Sharing rooms name: security tips{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-3"}>
                    <CardBody className="opacity-8">
                    A room name or link is all it takes to join a meeting. It's important to 
                    understand ways to secure access to this information. For a start, only share
                     meeting-links with trusted people using secure channels like encrypted
                      emails/chat-services. Never broadcast your room link on social media, unless 
                      you are open to the idea of random people joining on a conversation. Avoid 
                      posting room links on public websites, broadcast lists, and public news feeds for that same reason.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingFour">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-4"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-4" ? "" : "collapse-4"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        Can I reuse a room name?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-4"}>
                    <CardBody className="opacity-8">
                    We strongly urge you to not do that. Although a room name appears only once on the product, 
                    creating new rooms with different names every-time dramatically reduced the probability of 
                    someone trying to guess your session-name. This also safeguards against your participants 
                    inadvertently sharing your 'reused' room name somewhere else.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingFifth">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-5"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-5" ? "" : "collapse-5"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        Can I email you guys?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-5"}>
                    <CardBody className="opacity-8">
                    We would love to hear from you! Email our team at hello@securemeeting.org.
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SupportAccordion;
