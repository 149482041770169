import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

import TeamCardFront from "components/team-cards/TeamCardFront";
import TeamCardBack from "components/team-cards/TeamCardBack";
import TeamCard from "components/team-cards/TeamCard";

//images

import Bryan from "assets/img/faces/bryan.jpeg";
import Justin from "assets/img/faces/justin.jpeg";
import Mukund from "assets/img/faces/mukund.jpeg";
import Marcos from "assets/img/faces/marcos.jpeg";

function Team2() {
  return (
    <>
      <div className="mt-8">
        <Container>
          <Row>
            <Col className="mx-auto text-center mb-5" md="8">
              <h3 className="display-3 text-primary">Meet the Team</h3>
              <h4 className="lead mt-1">
                Four individuals, united by a mission to provide the safest
                meeting place for all.
              </h4>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg="4" md="6">
              <TeamCard>
                <TeamCardFront
                  name="Bryan Kyritz"
                  cofounder={true}
                  job="CTO"
                  pic={Bryan}
                  emailName="bryan"
                  linkedin="https://www.linkedin.com/in/bryan-kyritz-500b40179/"
                />
                {/* <TeamCardBack
                  name="Bryan Kyritz"
                  desc="Bryan leads the product development at SecureMeeting and is
                   a full stack developer. He is also the co-founder and CTO of Cympl.io. 
                   Currently a rising junior at ECE, Stevens. Outside of work, his other 
                   goal is to get a motorcycle license and start riding the open roads."
                /> */}
              </TeamCard>
            </Col>

            <Col lg="4" md="6">
              <TeamCard>
                <TeamCardFront
                  name="Marcos Aurelio Salazar Torres"
                  job="Blockchain Developer"
                  pic={Marcos}
                  emailName="marcos"
                  linkedin="https://www.linkedin.com/in/marcos-aurelio-salazar-torres-02b993211/"
                />
                {/*
                 <TeamCardBack
                  name="Mukund Iyengar"
                  desc="Mukund has led SecureMeeting since it's inception as a side-project
                   in 2018. He holds a Ph.D. in live-streaming. Currently a faculty member 
                   at ECE, Stevens. Founder and director of Launchpad and iSTEM, and surrounded 
                   by incredibly talented students. Outside of work, he loves calligraphy 
                   and learning to play the piano."
                /> */}
              </TeamCard>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="4" md="6">
              <TeamCard>
                <TeamCardFront
                  name="Mukund Iyengar"
                  cofounder={true}
                  job="Director"
                  pic={Mukund}
                  emailName="mukund"
                  linkedin="https://www.linkedin.com/in/mukund-iyengar-ab6496128/"
                />
                {/* <TeamCardBack
                  name="Mukund Iyengar"
                  desc="Mukund has led SecureMeeting since it's inception as a side-project
                   in 2018. He holds a Ph.D. in live-streaming. Currently a faculty member 
                   at ECE, Stevens. Founder and director of Launchpad and iSTEM, and surrounded 
                   by incredibly talented students. Outside of work, he loves calligraphy 
                   and learning to play the piano."
                /> */}
              </TeamCard>
            </Col>

            <Col lg="4" md="6">
              <TeamCard>
                <TeamCardFront
                  name="Justin Trugman"
                  cofounder={true}
                  job=""
                  pic={Justin}
                  emailName="justin"
                  linkedin="https://www.linkedin.com/in/justin-trugman/"
                />
                {/* <TeamCardBack
                  name="Justin Trugman"
                  desc="Justin has been involved with SecureMeeting in various capacities 
                  since it's inception, and currently helps out with system architecture 
                  and microservice build maps. An ex-Googler, he is currently a software 
                  dev at Caregility. He loves all things technology and entrepreneurship."
                /> */}
              </TeamCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Team2;
